export function createAction(type, payload) {
    return payload === undefined
        ? { type }
        : { type, payload };
}
export function createThunkAction(thunkAction) {
    // set the Type to unknown. This function is passed through
    // `redux-thunk` and so the return value should be the type
    // Due the original Dispatch method not having the correct
    // ReturnType this is an alternative way to get it
    const processedThunkAction = thunkAction;
    return processedThunkAction;
}
