import * as React from 'react';
import cx from 'classnames';
import styles from './SettingsBlock.scss';
const SettingsBlockText = (props) => {
    const classes = cx(styles.text, {
        [styles.isLabel]: props.size === 'label',
    });
    return (React.createElement("div", { className: classes }, props.children));
};
export default SettingsBlockText;
