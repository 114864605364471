import * as React from 'react';
import cx from 'classnames';
import { LocaleContext } from '@source/view/enhancers/Locale';
import ChannelSelector from '../ChannelSelector';
import Icon from '../Icon';
import styles from './VideoPlayerUi.ChannelSelect.scss';
export default class ChannelSelect extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isActive: false,
        };
        this.toggle = () => this.setState(s => ({ isActive: !s.isActive }));
    }
    render() {
        const logoStyles = {
            backgroundImage: this.props.channelLogo
                ? `url("${this.props.channelLogo}")`
                : undefined,
        };
        const containerClasses = cx(styles.container, this.props.className, {
            [styles.isActive]: this.state.isActive,
        });
        return (React.createElement("div", { className: containerClasses },
            this.state.isActive && (React.createElement("div", { className: styles.containerWrapper },
                React.createElement("div", { className: styles.containerTitle },
                    React.createElement("span", null, this.context.translate('select_a_channel')),
                    React.createElement("button", { onClick: this.toggle },
                        React.createElement(Icon, { iconName: "close" }))),
                React.createElement(ChannelSelector, { onItemClick: this.toggle, currentChannelId: this.props.currentChannelId, className: styles.containerSelector }))),
            React.createElement("button", { className: styles.button, onClick: this.toggle },
                React.createElement("div", { className: styles.buttonLogo, style: logoStyles }),
                React.createElement(Icon, { className: styles.buttonArrow, iconName: "arrowDown" }))));
    }
}
ChannelSelect.contextType = LocaleContext;
