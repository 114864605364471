import paths from '@source/core/constants/paths';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import GuidePage from './pages/Guide';
import PrivacyPage from './pages/Privacy';
import VideoPage from './pages/Video';
import SettingsPage from './pages/Settings';
import RegisterPage from './pages/Register';
import EmailActivationPage from './pages/EmailActivation';
import AccountExtendPage from './pages/AccountExtend';
import EmailResetPage from './pages/EmailReset';
export const allRoutes = [
    {
        path: paths.login,
        component: LoginPage,
        exact: true,
        permissions: {
            loggedIn: false,
        },
    },
    {
        path: paths.register,
        component: RegisterPage,
        exact: true,
        permissions: {
            loggedIn: false,
        },
    },
    {
        path: paths.activate,
        component: EmailActivationPage,
        exact: true,
        permissions: {
            loggedIn: false,
        },
    },
    {
        path: paths.forgotPassword,
        component: EmailResetPage,
        exact: true,
        permissions: {
            loggedIn: false,
        },
    },
    {
        path: paths.extend,
        component: AccountExtendPage,
        exact: true,
        permissions: {
            loggedIn: true,
        },
    },
    {
        path: paths.guide,
        component: GuidePage,
        exact: true,
        permissions: {
            loggedIn: true,
            subscribed: true,
            activated: true,
        },
    },
    {
        path: paths.privacy,
        component: PrivacyPage,
        exact: true,
        permissions: {},
    },
    {
        path: paths.settings,
        component: SettingsPage,
        exact: true,
        permissions: {
            loggedIn: true,
            subscribed: true,
            activated: true,
        },
    },
    {
        path: '/video/:channelName',
        component: VideoPage,
        exact: true,
        permissions: {
            loggedIn: true,
            subscribed: true,
            activated: true,
        },
    },
    {
        path: paths.home,
        component: HomePage,
        exact: true,
        permissions: {
            loggedIn: true,
            subscribed: true,
            activated: true,
        },
    },
];
export function getRoutes(routes, permissions) {
    function hasPermission(route) {
        if (route.permissions) {
            if (route.permissions.loggedIn !== undefined && route.permissions.loggedIn !== permissions.loggedIn)
                return false;
            if (route.permissions.subscribed !== undefined && route.permissions.subscribed !== permissions.subscribed)
                return false;
        }
        return true;
    }
    const filteredRoutes = routes.filter(hasPermission);
    const redirect = (() => {
        if (!permissions.loggedIn) {
            return paths.login;
        }
        if (!permissions.subscribed) {
            return paths.extend;
        }
        return paths.home;
    })();
    return {
        redirect,
        routes: filteredRoutes,
    };
}
