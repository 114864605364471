import * as React from 'react';
import { connect } from 'react-redux';
import memoize from 'lodash/memoize';
import { Page, Grid, Container, ContentTile, HeroHighlight } from '@source/view/components';
import paths from '@source/core/constants/paths';
import { getChannelItems, getCurrenttime, fetchChannels } from '@source/core/store/channel';
import getCurrentProgram from '@source/core/utils/getCurrentProgram';
import styles from './Home.styles.scss';
import { LocaleContext } from '@source/view/enhancers/Locale';
function getHeroChannel(channels) {
    return channels.find(channel => channel.isHero);
}
class DashboardHomePage extends React.Component {
    constructor() {
        super(...arguments);
        this.getHeroChannel = memoize(getHeroChannel);
    }
    componentDidMount() {
        this.props.fetchChannels();
    }
    render() {
        const { translate } = this.context;
        const { channels, currentTime } = this.props;
        const heroChannel = this.getHeroChannel(channels);
        return (React.createElement(Page, { title: translate('watch_tv'), className: styles.wrapper, pageType: "dashboard", disableHeaderSpacing: true },
            (() => {
                if (heroChannel) {
                    const { currentProgram } = getCurrentProgram(channels, heroChannel.id, currentTime);
                    return (React.createElement(HeroHighlight, { className: styles.heroHighlight, channelId: heroChannel.id, channelName: heroChannel.slug, channelLogo: heroChannel.logoImageUrl, backgroundImage: currentProgram ? currentProgram.imageUrlLandscape : undefined, subtext: this.context.translate('watch_tv_hero_label'), title: currentProgram ? currentProgram.name : undefined, startTime: currentProgram ? currentProgram.startTime : undefined, endTime: currentProgram ? currentProgram.endTime : undefined }));
                }
                return null;
            })(),
            React.createElement(Container, { fullWidth: true, className: styles.content },
                React.createElement(Grid.Row, null, channels.map((channel) => {
                    const { currentProgram } = getCurrentProgram(channels, channel.id, currentTime);
                    return (React.createElement(Grid.Column, { key: channel.id, medium: 4, large: 3 },
                        React.createElement(ContentTile, { link: paths.video(channel.id, channel.slug), channelLogo: channel.logoImageUrl, title: currentProgram ? currentProgram.name : undefined, startTime: currentProgram ? currentProgram.startTime : undefined, endTime: currentProgram ? currentProgram.endTime : undefined, currentTime: currentTime, image: currentProgram ? currentProgram.imageUrlLandscape : undefined })));
                })))));
    }
}
DashboardHomePage.contextType = LocaleContext;
const mapState = (state) => ({
    channels: getChannelItems(state),
    currentTime: getCurrenttime(state),
});
const mapDispatch = {
    fetchChannels,
};
// @ts-ignore Need to rewrite the Prop injection
export default connect(mapState, mapDispatch)(DashboardHomePage);
