import * as React from 'react';
import cx from 'classnames';
import { Icon } from '@source/view/components';
import VolumeControl from '../VolumeControl';
import styles from './VideoPlayerUi.scss';
export default class VideoPlayerControls extends React.Component {
    constructor() {
        super(...arguments);
        this.handleBack = () => {
            if (this.props.handleBack) {
                this.props.handleBack();
            }
        };
    }
    render() {
        const { isFullscreen, handleBack, handleFullscreen, handlePlay, playing } = this.props;
        return (React.createElement(React.Fragment, null,
            this.props.handleBack && (React.createElement("button", { className: styles.button, onClick: handleBack },
                React.createElement(Icon, { iconName: "backArrow" }))),
            React.createElement("div", { className: styles.optionsFill }),
            React.createElement("div", { className: styles.options },
                React.createElement("google-cast-launcher", { class: styles.googleCastLauncher }),
                React.createElement(VolumeControl, { className: styles.optionsOption }),
                React.createElement("button", { className: cx(styles.optionsOption, styles.button), onClick: handleFullscreen },
                    React.createElement(Icon, { iconName: isFullscreen ? 'fullscreenClose' : 'fullscreenOpen' })))));
    }
}
