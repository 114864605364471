import * as React from 'react';
import useOffsets from '@source/view/hooks/useOffsets';
import useLineHeight from '@source/view/hooks/useLineHeight';
import { LocaleContext } from '@source/view/enhancers/Locale';
export default function ShowMore(props) {
    const { text, lines, className } = props;
    const [showMore, setShowMore] = React.useState(false);
    const innerRef = React.useRef(null);
    const innerOffsets = useOffsets(innerRef);
    const lineHeight = useLineHeight(innerRef);
    const maxHeight = lineHeight * lines;
    const hasMore = innerOffsets && innerOffsets.height > maxHeight;
    const toggleShowMore = React.useCallback(() => {
        setShowMore(s => !s);
    }, []);
    const { translate } = React.useContext(LocaleContext);
    return (React.createElement("div", { className: className },
        React.createElement("div", { style: { maxHeight: showMore ? undefined : `${maxHeight}px`, overflow: 'hidden' } },
            React.createElement("div", { ref: innerRef }, text)),
        React.createElement("br", null),
        (hasMore && !showMore) && (React.createElement("button", { type: "button", onClick: toggleShowMore, children: translate('show_more'), style: { padding: 0 } }))));
}
