import * as React from 'react';
class Swipable extends React.Component {
    constructor() {
        super(...arguments);
        this.containerRef = React.createRef();
        this.startX = 0;
        this.startY = 0;
        this.draggedX = 0;
        this.draggedY = 0;
        this.getMousePositions = (ev) => {
            const x = ev.touches[0].clientX;
            const y = ev.touches[0].clientY;
            return { x, y };
        };
        this.handleTouchStart = (ev) => {
            const { x, y } = this.getMousePositions(ev);
            this.startX = x;
            this.startY = y;
        };
        this.handleTouchMove = (ev) => {
            const { x, y } = this.getMousePositions(ev);
            this.draggedX = this.startX - x;
            this.draggedY = this.startY - y;
        };
        this.handleTouchEnd = () => {
            if (Math.abs(this.draggedY) > this.props.difference)
                return;
            if (Math.abs(this.draggedX) > this.props.difference) {
                if (this.props.handleSwipeLeft && this.draggedX > 0)
                    this.props.handleSwipeLeft();
                if (this.props.handleSwipeRight && this.draggedX < 0)
                    this.props.handleSwipeRight();
            }
        };
    }
    render() {
        return (React.createElement("div", { onTouchStart: this.handleTouchStart, onTouchMove: this.handleTouchMove, onTouchEnd: this.handleTouchEnd }, this.props.children));
    }
}
Swipable.defaultProps = {
    difference: 50,
};
export default Swipable;
