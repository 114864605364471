import fetchUrl from './fetchUrl';
import UserInfo from '@source/core/models/UserInfo';
export function subscriptionActivate(registerCode, userToken) {
    return fetchUrl({
        endpoint: '/subscription/activate',
        userToken,
        method: 'POST',
        body: {
            code: registerCode,
        },
    }).then(r => new UserInfo(r));
}
export function subscriptionCheck(registerCode, userToken) {
    return fetchUrl({
        endpoint: '/subscription/check',
        userToken,
        method: 'POST',
        body: {
            code: registerCode,
        },
    });
}
