import { createStore, compose, applyMiddleware } from 'redux';
import { default as thunk } from 'redux-thunk';
import { StoreState, reducers } from './reducers';
const composeEnhancers = process.env.NODE_ENV === 'development'
    // eslint-disable-next-line no-underscore-dangle
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            actionsBlacklist: ['channel/SET_CURRENT_TIME'],
        })
        : compose
    : compose;
function configureStore() {
    const initialState = {};
    const middleware = [thunk];
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middleware)));
    if (process.env.NODE_ENV === 'development') {
        if (module.hot) {
            module.hot.accept('./reducers', () => {
                store.replaceReducer(require('./reducers').reducers);
            });
        }
    }
    return {
        store,
    };
}
export { StoreState, configureStore };
