import * as React from 'react';
import Bowser from 'bowser';
import styles from './BrowserSupportedNotification.scss';
import { LocaleContext } from '@source/view/enhancers/Locale';
const SUPPORTED_BROWSERS_CONFIG_URL = 'https://zstvvinson.blob.core.windows.net/configs/unsupported_browsers.json';
const browserSupportedConfig = fetch(SUPPORTED_BROWSERS_CONFIG_URL)
    .then(async (r) => {
    const value = await r.json();
    return value;
});
async function checkBrowserSupported() {
    try {
        const parsed = Bowser.getParser(window.navigator.userAgent);
        const { name } = parsed.getBrowser();
        if (!name)
            return false;
        const lowercasedName = name.toLocaleLowerCase();
        const list = await browserSupportedConfig;
        return !list.find(i => i.name.toLowerCase() === lowercasedName);
        ;
    }
    catch (err) {
        return false;
    }
}
const BrowserSupportedNotification = () => {
    const [supported, setSupported] = React.useState(true);
    const { translate } = React.useContext(LocaleContext);
    const [hidden, setHidden] = React.useState(false);
    const hide = React.useCallback(() => setHidden(true), []);
    React.useEffect(() => {
        checkBrowserSupported().then(setSupported);
    });
    return (!supported && !hidden)
        ? (React.createElement("div", { className: styles.container },
            React.createElement("span", { className: styles.message }, translate('browser_not_supported')),
            React.createElement("button", { onClick: hide, className: styles.closeButton }, "x")))
        : null;
};
export default BrowserSupportedNotification;
