import * as React from 'react';
import styles from './Checkbox.scss';
function createInputId() {
    return `input-${Math.round(Math.random() * 3)}`;
}
class Checkbox extends React.Component {
    constructor() {
        super(...arguments);
        this.inputId = createInputId();
        this.handleInputChange = (event) => {
            const value = event.currentTarget.checked;
            // pass value to parent component
            if (this.props.onChange) {
                this.props.onChange(value, event);
            }
        };
    }
    render() {
        const { checked, label, } = this.props;
        return (React.createElement("label", { htmlFor: this.inputId, className: styles.checkbox },
            React.createElement("input", { id: this.inputId, className: styles.input, type: "checkbox", checked: checked, onChange: this.handleInputChange }),
            React.createElement("div", { className: styles.checkmark }),
            React.createElement("span", { className: styles.text }, label)));
    }
}
export default Checkbox;
