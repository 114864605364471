import { fromProgramList } from './Program';
import stringToSlug from '../utils/stringToSlug';
export class Channel {
    constructor(channelData, programListData) {
        this.id = channelData.id.toString();
        this.name = channelData.name;
        this.slug = stringToSlug(channelData.name);
        this.logoImageUrl = channelData.lightIcon;
        this.programs = programListData === undefined
            ? null
            : fromProgramList(programListData, this);
        this.isHero = !!channelData.hero;
    }
}
