import { unixTimestampToDate } from '@source/core/utils/formatDate';
export class Program {
    constructor(data, channel, endTime) {
        this.id = data.id.toString();
        this.channelId = channel.id;
        this.name = data.name;
        this.description = data.description || '';
        this.startTime = data.time;
        this.startDate = unixTimestampToDate(this.startTime);
        this.endTime = endTime !== undefined
            ? endTime
            : this.startTime + 30 * 60;
        this.endDate = unixTimestampToDate(this.endTime);
        this.duration = this.endTime - this.startTime;
        this.imageUrlLandscape = data.imageUrlLandscape;
        this.imageUrlPortrait = data.imageUrlPortrait;
    }
}
export function fromProgramList(data, channel) {
    if (data) {
        return data.map((programData, index, list) => {
            const nextProgramData = list[index + 1];
            const nextProgramDataTime = nextProgramData
                ? nextProgramData.time
                : undefined;
            return new Program(programData, channel, nextProgramDataTime);
        });
    }
    return null;
}
