import React from 'react';
import classnames from 'classnames';
import { default as SelectOption } from './Select.Option';
import { Icon } from '../../components';
import styles from './Select.scss';
export default class Select extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            open: false,
        };
        this.toggleSelect = () => this.setState(state => ({
            open: !state.open,
        }));
        this.openSelect = () => this.setState({ open: true });
        this.closeSelect = () => this.setState({ open: false });
        this.handleKeyDown = () => { };
        this.createOptionClick = (value) => () => {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        };
        this.getOptionValues = (children) => React.Children
            .toArray(children)
            .reduce((acc, child) => {
            if (React.isValidElement(child)) {
                return acc.concat({
                    value: child.props.value,
                    label: child.props.label,
                    onClick: this.createOptionClick(child.props.value),
                });
            }
            return acc;
        }, []);
        this.getSelectedOption = (options, selectedValue) => (options.find(option => option.value === selectedValue));
    }
    render() {
        const { children, value, placeholder } = this.props;
        const options = this.getOptionValues(children);
        const selectedOption = this.getSelectedOption(options, value);
        const classes = classnames(styles.select, {
            [styles.isOpen]: this.state.open,
            [styles.hasValue]: !!selectedOption,
        });
        return (React.createElement("div", { className: classes, onClick: this.toggleSelect, onKeyDown: this.handleKeyDown, tabIndex: 0, role: "button" },
            React.createElement("div", { className: styles.selectLabel }, selectedOption ? selectedOption.label : placeholder),
            React.createElement("div", { className: styles.selectOptions }, options.map((option) => {
                const optionClasses = classnames(styles.option, {
                    [styles.isSelected]: option.value === value,
                });
                return (React.createElement("div", { key: option.value, "data-value": option.value, className: optionClasses, role: "button", tabIndex: -0, onClick: option.onClick, onKeyDown: option.onClick },
                    React.createElement(Icon, { className: styles.optionIcon, iconName: "checkMark" }),
                    React.createElement("span", { className: styles.optionText }, option.label)));
            })),
            React.createElement(Icon, { className: styles.selectArrow, iconName: "chevron-down" })));
    }
}
Select.Option = SelectOption;
Select.defaultProps = {
    children: null,
    onChange: null,
    placeholder: null,
};
