import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import paths from '@source/core/constants/paths';
import { LocaleContext } from '@source/view/enhancers/Locale';
import styles from './Footer.scss';
import { Grid, Container } from '../../components';
function mapState(state) {
    return {
        providerName: state.user.providerName || null,
        providerEmail: state.user.providerEmail || null,
    };
}
class Footer extends React.Component {
    render() {
        const { providerEmail, providerName } = this.props;
        const footerMenu = [
            {
                id: '1',
                label: 'Ziggo student',
                items: [
                    {
                        label: this.context.translate('watch_tv'),
                        link: paths.home,
                    },
                    {
                        label: this.context.translate('tv_guide'),
                        link: paths.guide,
                    },
                    {
                        label: this.context.translate('settings'),
                        link: paths.settings,
                    },
                ],
            },
            {
                id: '2',
                label: 'Ziggo student',
                items: [
                    {
                        label: 'Ziggo Privacy Statement',
                        link: 'privacy',
                        external: false,
                    },
                    {
                        label: 'Ziggo Gebruiksvoorwaarden',
                        link: '/docs/2019-03_GebruikersvoorwaardenTV_Now_(1.0).pdf',
                        external: true,
                    },
                    {
                        label: 'Ziggo FAQ',
                        link: '/docs/2019-03_FAQ_TV_Now_(1.0).pdf',
                        external: true,
                    },
                ],
            },
            {
                id: '3',
                label: 'Contact',
                items: [
                    {
                        label: this.context.translate('footer_label_contact_provider', {
                            name: providerName || '',
                        }),
                        link: `mailto:${providerEmail}`,
                        external: true,
                    },
                ],
            },
        ];
        const year = new Date().getFullYear();
        const footerColumn = footerMenu.map((column) => {
            const items = column.items.map(item => (React.createElement("li", { key: item.label, className: styles.listItem }, item.external === true ? (React.createElement("a", { className: styles.link, href: item.link, rel: "noopener noreferrer", target: "_blank" }, item.label)) : (React.createElement(NavLink, { className: styles.link, to: item.link }, item.label)))));
            return (React.createElement(Grid.Column, { key: column.id, className: styles.column },
                React.createElement("h2", { className: styles.title }, column.label),
                React.createElement("ul", { className: styles.list }, items)));
        });
        return (React.createElement("footer", { className: styles.footer },
            React.createElement(Container, null,
                React.createElement(Grid.Row, { xSmall: 12, medium: 6, large: 3 }, footerColumn),
                React.createElement("small", { className: styles.copyright },
                    "\u00A9 ",
                    year,
                    " Ziggo. Alle rechten voorbehouden."))));
    }
}
Footer.contextType = LocaleContext;
export default connect(mapState)(Footer);
