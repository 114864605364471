import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Markdown from '@source/view/components/Markdown';
import queryString from 'query-string';
import * as vinson from '@source/core/services/vinson';
import { Page, Input, Container, Button, Steps, Form } from '@source/view/components';
import isEmailAdress from '@source/core/utils/isEmailAdress';
import isValidPassword from '@source/core/utils/isValidPassword';
import paths from '@source/core/constants/paths';
import { getToken } from '@source/core/store/user';
import { logError } from '@source/core/utils/logger';
import { LocaleContext } from '@source/view/enhancers/Locale';
function mapState(state) {
    return {
        userToken: getToken(state),
    };
}
class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleToken = async () => {
            try {
                this.setState({ pending: true, errorMessage: '' });
                if (this.state.registrationCode) {
                    const { success } = await vinson.subscriptionCheck(this.state.registrationCode);
                    if (!success) {
                        throw new Error('Code is not correct');
                    }
                    this.setState({ errorMessage: null, viewState: 'register' });
                }
            }
            catch (err) {
                const errorMessage = err.message || 'There needs to be a token';
                this.setState({ errorMessage });
            }
            finally {
                this.setState({ pending: false });
            }
        };
        this.handleRegister = async () => {
            try {
                const { email, password, registrationCode } = this.state;
                this.setState({ pending: true });
                await vinson.userRegister(email, password, registrationCode);
                this.setState({ viewState: 'confirmed', errorMessage: null });
            }
            catch (err) {
                if (err.response != null && err.response.status === 409) {
                    this.setState({ errorMessage: '409' });
                }
                else {
                    logError(err);
                    this.setState({ errorMessage: 'something went wrong' });
                }
            }
            finally {
                this.setState({ pending: false });
            }
        };
        this.handleChange = (key) => (value) => {
            switch (key) {
                case 'email':
                    this.setState({ email: value, retypeEmail: '' });
                    break;
                case 'retypeEmail':
                    this.setState({ retypeEmail: value });
                    break;
                case 'password':
                    this.setState({ password: value, retypePassword: '' });
                    break;
                case 'retypePassword':
                    this.setState({ retypePassword: value });
                    break;
                case 'registrationCode':
                    this.setState({ registrationCode: value });
                    break;
            }
        };
        const { query } = queryString.parseUrl(props.location.search);
        this.state = {
            viewState: 'token',
            email: '',
            retypeEmail: '',
            password: '',
            retypePassword: '',
            registrationCode: query.activationCode || '',
            pending: false,
            errorMessage: null,
        };
    }
    render() {
        const { translate } = this.context;
        const { pending, viewState, errorMessage, email, retypeEmail, password, retypePassword, registrationCode, } = this.state;
        const emailMatch = email === retypeEmail;
        const passwordMatch = password === retypePassword;
        const isDTVNow = window.location.hostname === 'dtvnow.nl' || window.location.hostname === 'www.dtvnow.nl';
        return (React.createElement(Page, { title: "Registreren" },
            React.createElement(Container, { theme: "slim" },
                React.createElement(Form.Heading, { align: "center" }, translate('account_register_heading')),
                React.createElement(Steps, null,
                    React.createElement(Steps.Item, { label: translate('account_register_code'), active: viewState === 'token' }),
                    React.createElement(Steps.Item, { label: "Gegevens", active: viewState === 'register' }),
                    React.createElement(Steps.Item, { label: "Bevestig", active: viewState === 'confirmed' })),
                errorMessage && (React.createElement(Form.ErrorMessage, null,
                    React.createElement(Markdown, { source: translate(errorMessage === '409' ? 'error_message_email_exists' : 'account_register_code_errorlabel') }))),
                (() => {
                    switch (viewState) {
                        case 'token': return (React.createElement(Form, { onSubmit: this.handleToken },
                            React.createElement(Form.Text, null,
                                React.createElement("p", null, translate('account_register_code_text'))),
                            React.createElement(Input, { onChange: this.handleChange('registrationCode'), value: registrationCode, placeholder: translate('account_register_code_inputplaceholder'), error: !!errorMessage }),
                            React.createElement("br", null),
                            React.createElement(Form.Text, null,
                                React.createElement(Markdown, { source: translate(isDTVNow ? 'request_voucher_vds_dtvnow' : 'request_voucher_vds') })),
                            React.createElement(Form.Row, null,
                                React.createElement(Link, { className: Form.rowFillClassName, to: paths.login }, `< ${translate('back')}`),
                                React.createElement(Button, { theme: "primary", fullWidth: true, onClick: this.handleToken, text: translate('next') }))));
                        case 'register': return (React.createElement(Form, { onSubmit: this.handleRegister },
                            React.createElement("input", { type: "submit", style: { display: 'none', visibility: 'hidden' } }),
                            React.createElement(Form.Text, null, translate('account_register_information_text')),
                            React.createElement(Input, { label: "E-mail", type: "text", onChange: this.handleChange('email'), value: email, placeholder: translate('account_register_inputplaceholder_email'), error: !isEmailAdress(email) }),
                            React.createElement(Input, { type: "text", onChange: this.handleChange('retypeEmail'), placeholder: translate('account_register_inputplaceholder_repeat_email'), value: retypeEmail, error: retypeEmail !== '' && !emailMatch && translate('account_register_email_errorlabel') }),
                            React.createElement("br", null),
                            React.createElement(Input, { label: "Wachtwoord", type: "password", placeholder: translate('account_register_inputplaceholder_password'), onChange: this.handleChange('password'), value: password, error: !isValidPassword(password) }),
                            React.createElement(Input, { type: "password", onChange: this.handleChange('retypePassword'), placeholder: translate('account_register_inputplaceholder_repeat_password'), value: retypePassword, error: retypePassword !== '' && !passwordMatch && translate('account_register_password_errorlabel') }),
                            React.createElement("br", null),
                            React.createElement(Form.Row, null,
                                React.createElement(Link, { className: Form.rowFillClassName, to: paths.login }, '< Terug'),
                                React.createElement(Button, { theme: "primary", fullWidth: true, onClick: this.handleRegister, text: translate('account_register_submit') }))));
                        default: return (React.createElement(React.Fragment, null,
                            React.createElement(Form.Text, null,
                                React.createElement("p", null, translate('account_register_success'))),
                            React.createElement(Link, { to: paths.login }, `< ${translate('back')}`)));
                    }
                })())));
    }
}
RegisterPage.contextType = LocaleContext;
export default withRouter(connect(mapState)(RegisterPage));
