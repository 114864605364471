import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import styles from './SwimmingLane.scss';
import { getCurrentPlayingProgram } from '@source/core/store/channel';
import { HEIGHT_PER_MINUTE } from '@source/core/constants/sizes';
const SECONDS_IS_COMPACT = 60 * 20;
const SECONDS_IS_SLIM = 60 * 13;
const SECONDS_IS_TOOLTIP = 60 * 7;
const calculateHeight = (miliseconds) => `${(miliseconds / 60) * HEIGHT_PER_MINUTE}px`;
function formatDate(date) {
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hour}:${minutes}`;
}
function mapState(store, props) {
    const { currentProgram } = getCurrentPlayingProgram(store, props.id);
    return {
        currentProgram,
    };
}
function SwimmingLaneRow(props) {
    const { onChannelClick, currentProgram, selectedProgramId, className } = props;
    function handleChannelClick(programId) {
        if (onChannelClick)
            onChannelClick(programId);
    }
    return (React.createElement("div", { ref: props.innerRef, className: cx(styles.item, className) },
        React.createElement("div", { className: styles.itemInner },
            React.createElement("div", { className: styles.programContainer }, props.programs && props.programs.map((program) => {
                const programStyles = {
                    height: calculateHeight(program.duration),
                };
                const activeClassname = ((program.duration <= SECONDS_IS_TOOLTIP && styles.isTooltip) ||
                    (program.duration <= SECONDS_IS_SLIM && styles.isSlim) ||
                    (program.duration <= SECONDS_IS_COMPACT && styles.isCompact));
                const programClasses = cx(styles.program, activeClassname, {
                    [styles.isPlaying]: currentProgram && currentProgram.id === program.id,
                    [styles.isSelected]: program.id === selectedProgramId,
                });
                return (React.createElement("div", { className: programClasses, style: programStyles, key: program.id, onClick: () => handleChannelClick(program.id) },
                    React.createElement("div", { className: styles.programInner },
                        React.createElement("div", { className: styles.programContent },
                            React.createElement("div", { className: styles.programTime }, formatDate(program.startDate)),
                            React.createElement("div", { className: styles.programName }, program.name)))));
            })))));
}
export default connect(mapState)(SwimmingLaneRow);
