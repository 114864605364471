import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Page, Container, Input, Button, Checkbox, Form } from '../components';
import { loginUser, setPersist, getPersist } from '../../core/store/user';
import { LocaleContext } from '@source/view/enhancers/Locale';
import paths from '@source/core/constants/paths';
import isEmailAdress from '@source/core/utils/isEmailAdress';
import isValidPassword from '@source/core/utils/isValidPassword';
import Markdown from '@source/view/components/Markdown';
import $ from './Login.scss';
const mapDispatch = {
    loginUser,
    setPersist,
};
const mapState = (store) => ({
    persisted: getPersist(store),
});
class LoginPage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            email: '',
            validEmail: false,
            password: '',
            validPassword: false,
            errorMessage: null,
        };
        this.validateState = (state) => (Object.assign({}, state, { validEmail: isEmailAdress(state.email), validPassword: isValidPassword(state.password) }));
        this.handleUsernameInput = (email) => this.setState(state => this.validateState(Object.assign({}, state, { email })));
        this.handlePasswordInput = (password) => this.setState(state => this.validateState(Object.assign({}, state, { password })));
        this.handleLogin = async () => {
            const { translate } = this.context;
            try {
                this.setState({ errorMessage: null });
                const userInfo = await this.props.loginUser(this.state.email, this.state.password);
                if (userInfo.subscriptionAlmostExpired) {
                    this.props.history.push(paths.extend);
                }
                else {
                    this.props.history.push(paths.home);
                }
            }
            catch (err) {
                this.setState({ errorMessage: translate('login_errormessage_403') });
            }
        };
    }
    render() {
        const { translate } = this.context;
        const { errorMessage } = this.state;
        const { persisted, setPersist } = this.props;
        const renderAside = (React.createElement("small", { style: { display: 'block' } },
            `${translate('no_account_message')} `,
            React.createElement(Link, { to: paths.register }, translate('register'))));
        return (React.createElement(Page, { title: "Login", renderAside: renderAside, className: $.page, disablePageSpacing: true },
            React.createElement(Container, { className: $.container },
                React.createElement("div", { className: $.containerInner },
                    React.createElement(Form, { onSubmit: this.handleLogin, className: $.form },
                        React.createElement("br", null),
                        React.createElement(Form.Heading, null, translate('login_text')),
                        errorMessage && React.createElement(Form.ErrorMessage, null, errorMessage),
                        React.createElement(Input, { label: "E-mailadres", type: "text", onChange: this.handleUsernameInput, value: this.state.email, error: !this.state.validEmail }),
                        React.createElement(Input, { label: "Wachtwoord", type: "password", onChange: this.handlePasswordInput, value: this.state.password, linkLabel: "Wachtwoord vergeten?", linkHref: paths.forgotPassword, error: !this.state.validPassword }),
                        React.createElement("br", null),
                        React.createElement(Form.Row, null,
                            React.createElement(Checkbox, { label: "Blijf ingelogd", onChange: setPersist, checked: persisted }),
                            React.createElement(Button, { className: Form.rowFillClassName, fullWidth: true, theme: "primary", onClick: this.handleLogin, text: "inloggen" })),
                        React.createElement(Form.Text, { small: true },
                            React.createElement(Markdown, { source: translate('user_consent_label') })),
                        React.createElement("hr", null),
                        React.createElement("p", { style: { textAlign: 'center', marginBottom: '10px' } }, translate('no_account_message')),
                        React.createElement(Button, { as: Link, to: paths.register, text: this.context.translate('register'), fullWidth: true }))),
                React.createElement("div", { className: $.containerBackground }))));
    }
}
LoginPage.contextType = LocaleContext;
export default withRouter(connect(mapState, mapDispatch)(LoginPage));
