import { createSelector } from 'reselect';
import getCurrentProgram from '../utils/getCurrentProgram';
import * as channelServices from '@source/core/services/vinson';
import { getToken } from './user';
import { createAction, createThunkAction } from './utils';
/*
    Selectors
------------------------------------------ */
export const getChannel = (state) => state.channel;
export const getChannelItems = (state) => getChannel(state).items;
export const getCurrenttime = (state) => getChannel(state).currentTime;
export const getChannelById = createSelector((state) => getChannelItems(state), (state, channelId) => channelId, (channels, channelId) => {
    return channels.find(i => i.id === channelId);
});
export const getCurrentPlayingProgram = createSelector((state) => getChannelItems(state), (state, channelIdOrSlug) => channelIdOrSlug, (state) => getCurrenttime(state), getCurrentProgram);
/*
    Actions
------------------------------------------ */
var actionTypes;
(function (actionTypes) {
    actionTypes["PENDING"] = "channel/PENDING";
    actionTypes["RESOLVED"] = "channel/RESOLVED";
    actionTypes["ADD_CHANNELS"] = "channel/ADD_CHANNELS";
    actionTypes["SET_CURRENT_TIME"] = "channel/SET_CURRENT_TIME";
})(actionTypes || (actionTypes = {}));
const addChannels = (channels) => createAction(actionTypes.ADD_CHANNELS, channels);
const setPending = () => createAction(actionTypes.PENDING);
const setResolved = () => createAction(actionTypes.RESOLVED);
export const setCurrenttime = (currentTime) => createAction(actionTypes.SET_CURRENT_TIME, currentTime);
export const fetchChannels = () => createThunkAction(async (dispatch, getState) => {
    const userToken = getToken(getState());
    if (!userToken)
        throw new Error('cannot fetch channels without user token');
    dispatch(setPending());
    const channels = await channelServices.fetchChannelList(userToken);
    dispatch(setResolved());
    dispatch(addChannels(channels));
    return channels;
});
export const fetchChannelsIfNothing = fetchChannels;
const initialState = {
    items: [],
    pending: false,
    currentTime: 0,
};
export function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ADD_CHANNELS: return Object.assign({}, state, { items: action.payload });
        case actionTypes.PENDING: return Object.assign({}, state, { pending: true });
        case actionTypes.RESOLVED: return Object.assign({}, state, { pending: false });
        case actionTypes.SET_CURRENT_TIME: return Object.assign({}, state, { currentTime: action.payload });
        default: return state;
    }
}
