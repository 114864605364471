import * as React from 'react';
import cx from 'classnames';
import styles from './ProfileButton.scss';
import { connect } from 'react-redux';
import { getUserEmail, logoutUser } from '@source/core/store/user';
import Icon from '../Icon';
import { Link } from 'react-router-dom';
import paths from '@source/core/constants/paths';
import { LocaleContext } from '@source/view/enhancers/Locale';
const mapState = (state) => ({
    email: getUserEmail(state),
});
const mapDispatch = {
    logout: logoutUser,
};
class ProfileButton extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { isExpanded: false };
        this.toggleCollapse = () => this.setState(s => ({ isExpanded: !s.isExpanded }));
        this.handleMouseEnter = () => clearInterval(this.timeoutId);
        this.handleMouseLeave = () => {
            this.timeoutId = window.setTimeout(() => {
                this.setState({ isExpanded: false });
            }, 200);
        };
    }
    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }
    render() {
        const { email, logout } = this.props;
        const classes = cx(styles.profile, {
            [styles.isCollapsed]: this.state.isExpanded,
        });
        return (React.createElement("div", { className: classes, onMouseEnter: this.handleMouseEnter, onMouseLeave: this.handleMouseLeave },
            React.createElement("div", { className: styles.profileInner, onClick: this.toggleCollapse, role: "presentation" },
                React.createElement(Icon, { className: styles.profileIcon, iconName: "profile" }),
                React.createElement("span", { className: styles.profileLabel }, email),
                React.createElement(Icon, { className: styles.profileCollapseIcon, iconName: "chevron-down" })),
            this.state.isExpanded && (React.createElement("div", { className: styles.optionContainer },
                React.createElement(Link, { to: paths.settings, className: styles.option },
                    React.createElement(Icon, { iconName: "settings", className: styles.optionIcon }),
                    React.createElement("span", { className: styles.optionLabel }, this.context.translate('settings'))),
                React.createElement("button", { onClick: logout, className: styles.option },
                    React.createElement(Icon, { iconName: "logout", className: styles.optionIcon }),
                    React.createElement("span", { className: styles.optionLabel }, this.context.translate('logout')))))));
    }
}
ProfileButton.contextType = LocaleContext;
// @ts-ignore Need to rewrite the Prop injection
export default connect(mapState, mapDispatch)(ProfileButton);
