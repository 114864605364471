import axios from 'axios';
const API_URL = 'https://vinson-zztv.triple-it.nl/v4api';
function toUrlEncoded(body) {
    return Object.keys(body).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(body[k].toString())}`).join('&');
}
export default async function fetchUrl({ endpoint, method = 'GET', body, userToken, }) {
    const params = {
        method,
        data: body ? toUrlEncoded(body) : undefined,
        headers: (() => {
            const headers = {};
            if (userToken) {
                headers.Authorization = `Bearer ${userToken}`;
            }
            if (!!body) {
                headers['Content-Type'] = 'application/x-www-form-urlencoded';
            }
            return headers;
        })(),
    };
    const url = `${API_URL}${endpoint}`;
    const response = await axios(url, params);
    return response.data;
}
