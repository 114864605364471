import * as React from 'react';
import cx from 'classnames';
import { Icon, Spinner } from '@source/view/components';
import VideoPlayerControls from './VideoPlayerUi.Controls';
import VideoPlayerInfo from './VideoPlayerUi.Info';
import styles from './VideoPlayerUi.scss';
import { LocaleContext } from '@source/view/enhancers/Locale';
import { launchFullscreen, exitFullscreen } from '@source/core/utils/fullscreen';
export default class VideoPlayer extends React.Component {
    constructor() {
        super(...arguments);
        this.videoContainerRef = React.createRef();
        this.toggleFullscreen = () => {
            if (this.props.handleFullscreen) {
                this.props.handleFullscreen(!this.props.fullscreen);
            }
        };
        this.setFullscreen = (fullscreen) => {
            const videoContainerEl = this.videoContainerRef.current;
            try {
                if (videoContainerEl) {
                    if (fullscreen) {
                        launchFullscreen(videoContainerEl);
                    }
                    else {
                        exitFullscreen();
                    }
                }
            }
            catch (err) { /* swallows error */ }
        };
        this.togglePlay = () => {
            if (this.props.playing) {
                this.props.handlePause();
            }
            else {
                this.props.handlePlay();
            }
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.fullscreen !== prevProps.fullscreen) {
            this.setFullscreen(this.props.fullscreen || false);
        }
    }
    render() {
        const { playing, buffering, controlBar, bottomBar, playerRef, handleBackButton, hideControls, } = this.props;
        // @TODO find when this should be shown
        const overlayIsActive = false;
        const containerClasses = cx(styles.container, {
            [styles.hasControls]: !hideControls,
        });
        const playerClasses = cx(styles.containerVideo, styles.video, {
            [styles.isActive]: true,
        });
        const overlayClasses = cx(styles.overlay, {
            [styles.isActive]: overlayIsActive,
        });
        return (React.createElement("div", { className: containerClasses, id: "MeisterFullScreen", onMouseMove: this.props.onMouseMove, ref: this.videoContainerRef },
            (!buffering && !playing) && (React.createElement(Icon, { iconName: "play", onClick: this.togglePlay, style: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60px',
                    zIndex: 999999,
                    opacity: this.props.playing ? 0.3 : 1,
                } })),
            buffering && (React.createElement("div", { className: styles.buffering },
                React.createElement(Spinner, { className: styles.bufferingSpinner }),
                React.createElement("span", null,
                    this.context.translate('buffering'),
                    "..."))),
            overlayIsActive && (React.createElement("div", { className: overlayClasses },
                React.createElement("div", { className: styles.overlayInner }))),
            controlBar && (React.createElement("div", { className: styles.containerTop },
                React.createElement(VideoPlayerControls, { playing: this.props.playing, handlePlay: this.props.handlePlay, handlePause: this.props.handlePause, handleBack: handleBackButton, handleFullscreen: this.toggleFullscreen, isFullscreen: this.props.fullscreen }))),
            React.createElement("div", { className: playerClasses },
                React.createElement("div", { className: styles.videoInner, ref: playerRef })),
            bottomBar && (React.createElement("div", { className: styles.containerBottom }, React.cloneElement(bottomBar, {
                hideControls,
            })))));
    }
}
VideoPlayer.Info = VideoPlayerInfo;
VideoPlayer.contextType = LocaleContext;
