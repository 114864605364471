import { EventEmitter } from 'events';
import unquote from '@source/core/utils/unquote';
// the values that the types represents
const mediaQueryValue = {
    none: 0,
    'x-small': 1,
    small: 2,
    medium: 3,
    large: 4,
    'x-large': 5,
};
// a simple calculation that calculates if the current
// media query fits between a max/min
export function mediaQueryIsActive(currentMediaQuery, maxMediaQuery, minMediaQuery = 'none') {
    const current = mediaQueryValue[currentMediaQuery];
    const max = mediaQueryValue[maxMediaQuery];
    const min = mediaQueryValue[minMediaQuery];
    return (current >= min &&
        current <= max);
}
class MediaQueryListener extends EventEmitter {
    constructor() {
        super();
        this.currentBreakPoint = 'none';
        this.onWindowResize = () => {
            const afterElement = window.getComputedStyle ? window.getComputedStyle(document.body, ':after') : false;
            if (afterElement) {
                const breakPoint = unquote(afterElement.getPropertyValue('content'));
                if (this.currentBreakPoint !== breakPoint) {
                    this.currentBreakPoint = breakPoint;
                    this.emit('change', this.currentBreakPoint);
                }
            }
        };
        ['resize', 'orientationchange', 'load'].forEach((eventName) => {
            window.addEventListener(eventName, this.onWindowResize);
        });
    }
}
export const mediaQuery = new MediaQueryListener();
