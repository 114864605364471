import * as React from 'react';
import Spinner from '../Spinner';
import styles from './VideoPlayer.Loading.styles.scss';
function VideoplayerLoadingScreen(props) {
    return (React.createElement("div", { className: styles.loading },
        React.createElement("div", { className: styles.loadingContainer },
            props.withSpinner && (React.createElement(Spinner, { className: styles.loadingSpinner })),
            props.title && React.createElement("div", { className: styles.loadingTitle }, props.title),
            props.message && (React.createElement("p", { className: styles.loadingText }, props.message)))));
}
export default VideoplayerLoadingScreen;
