export default function getElementOffset(el) {
    let top = 0;
    let left = 0;
    let element = el;
    // Loop through the DOM tree
    // and add it's parent's offset to get page offset
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        // @ts-ignore ignore as there is no easy way to guard the Type
        // without the use of instanceOf HTMLElement
        element = element.offsetParent;
    } while (element);
    return {
        left,
        top,
    };
}
