import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { getChannelItems, getCurrenttime } from '@source/core/store/channel';
import styles from './ChannelSelector.scss';
import ChannelSelectorItem from './ChannelSelector.Item';
class ChannelSelector extends React.Component {
    constructor() {
        super(...arguments);
        this.containerRef = React.createRef();
        this.selectedEl = null;
        this.scrollToSelected = () => {
            const containerEl = this.containerRef.current;
            const selectedEl = this.selectedEl;
            if (containerEl && selectedEl) {
                // center the scrollTop so the selected is visible and as close
                // to the center as possible
                containerEl.scrollTop = (
                // start at the top of the selected
                selectedEl.offsetTop
                    // minus half the container height
                    - (containerEl.offsetHeight / 2)
                    // add half the selected height
                    + (selectedEl.offsetHeight / 2));
            }
        };
    }
    componentDidMount() {
        this.scrollToSelected();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentChannelId !== this.props.currentChannelId) {
            this.scrollToSelected();
        }
    }
    render() {
        const { channels, onItemClick, className, currentChannelId, currentTime, } = this.props;
        return (React.createElement("div", { className: cx(styles.container, className) },
            React.createElement("div", { className: styles.containerInner, ref: this.containerRef }, channels.map((channel) => {
                const isSelected = currentChannelId === channel.id;
                return (React.createElement(ChannelSelectorItem, { key: channel.id, innerRef: isSelected ? (el) => { this.selectedEl = el; } : undefined, channels: channels, currentTime: currentTime, isSelected: isSelected, channel: channel, onItemClick: onItemClick }));
            }))));
    }
}
export default connect(state => ({
    channels: getChannelItems(state),
    currentTime: getCurrenttime(state),
}))(ChannelSelector);
