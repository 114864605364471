import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import ScrollArea from './components/ScrollArea';
import { DevTools, ToastMessage } from './components';
import { allRoutes, getRoutes } from './routes';
import * as user from '@source/core/store/user';
import * as notifications from '@source/core/store/notifications';
import { logError } from '@source/core/utils/logger';
import * as GoogleAnalyticService from '@source/core/services/google-analytics';
import paths from '@source/core/constants/paths';
import BrowserSupportedNotification from './components/BrowserSupportedNotification';
import LoadingScreen from './pages/LoadingScreen';
import styles from './app.styles.scss';
function mapStateToProps(state) {
    return {
        activated: user.getIsActivated(state),
        subscribed: user.getIsSubscribed(state),
        hasToken: !!user.getToken(state),
        notifications: notifications.getNotifications(state),
    };
}
const mapDispatchToProps = {
    checkUser: user.checkUser,
    addNotification: notifications.addNotification,
    removeNotification: notifications.removeNotification,
};
function getWindowSizes() {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
}
class App extends React.Component {
    constructor() {
        super(...arguments);
        this.state = Object.assign({ pending: true }, getWindowSizes());
        this.setSizes = debounce(() => {
            const sizes = getWindowSizes();
            this.setState(sizes);
        }, 500);
    }
    async componentDidMount() {
        GoogleAnalyticService.triggerPageChange(this.props.location.pathname);
        this.props.history.listen((location) => {
            GoogleAnalyticService.triggerPageChange(location.pathname);
        });
        window.addEventListener('resize', this.setSizes);
        try {
            this.setState({ pending: true });
            if (this.props.hasToken) {
                const [userInfo] = await Promise.all([
                    this.props.checkUser(),
                    new Promise(r => setTimeout(r, 500)),
                ]);
                if (userInfo.subscriptionAlmostExpired) {
                    this.props.history.push(paths.extend);
                }
            }
        }
        catch (err) {
            logError(err);
        }
        finally {
            this.setState({ pending: false });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setSizes);
    }
    render() {
        const { pending, width, height } = this.state;
        const { activated, subscribed, notifications, removeNotification, hasToken } = this.props;
        const { routes, redirect } = getRoutes(allRoutes, {
            activated,
            subscribed,
            loggedIn: hasToken,
        });
        const appStyles = {
            width: `${width}px`,
            height: `${height}px`,
        };
        return (React.createElement(ScrollArea, { className: styles.app, style: appStyles },
            React.createElement(BrowserSupportedNotification, null),
            React.createElement(DevTools, null),
            React.createElement(ToastMessage, { className: styles.toastMessage }, notifications.map(notification => (React.createElement(ToastMessage.Item, Object.assign({ key: notification.id, onRemove: removeNotification }, notification))))),
            pending ? (React.createElement(LoadingScreen, null)) : (React.createElement(Switch, null,
                routes.map(route => (React.createElement(Route, Object.assign({ key: route.path }, route)))),
                React.createElement(Redirect, { to: redirect })))));
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
