import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
export default function useResizeObserver(ref, handler) {
    const [resizeObserver] = React.useState(() => new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            handler({
                target: entry.target,
                contentRect: entry.contentRect,
            });
        });
    }));
    React.useEffect(() => {
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    return { resizeObserver };
}
