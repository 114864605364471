var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import styles from './Button.scss';
import Icon from '../Icon';
const Button = (_a) => {
    var { text, iconName, as: ElementType = 'button', fullWidth, type = 'button', theme = 'default', disabled = false, className } = _a, props = __rest(_a, ["text", "iconName", "as", "fullWidth", "type", "theme", "disabled", "className"]);
    const buttonClasses = classNames(styles.button, className, {
        [styles.isFull]: fullWidth,
        [styles.isPrimary]: theme === 'primary',
        [styles.isDisabled]: disabled,
        [styles.hasButton]: iconName,
    });
    if (ElementType === 'input') {
        return React.createElement(ElementType, Object.assign({ className: buttonClasses, disabled: disabled, type: type }, props));
    }
    return (React.createElement(ElementType, Object.assign({ className: buttonClasses, disabled: disabled, type: type }, props),
        iconName && React.createElement(Icon, { iconName: iconName, className: styles.buttonIcon }),
        React.createElement("span", null, text)));
};
export default Button;
