import * as React from 'react';
import { Page, Container, Form, Input, Steps, Button } from '../components';
import { userPasswordReset, userRequestPasswordReset } from '@source/core/services/vinson';
import { logError } from '@source/core/utils/logger';
import isEmailAdress from '@source/core/utils/isEmailAdress';
import isValidPassword from '@source/core/utils/isValidPassword';
import { LocaleContext } from '@source/view/enhancers/Locale';
import { parseUrl } from 'query-string';
import { withRouter } from 'react-router';
import paths from '@source/core/constants/paths';
class EmailResetPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleEmailSubmit = async () => {
            const { translate } = this.context;
            if (this.state.pending)
                return;
            try {
                const email = this.state.email.trim();
                if (email === '')
                    throw new Error('enter your email');
                if (!isEmailAdress(email))
                    throw new Error('enter a valid email');
                this.setState({ pending: true });
                const response = await userRequestPasswordReset(email);
                if (response.success !== true) {
                    throw new Error(translate('emailreset_error_message'));
                }
                this.setState({ email: '', viewState: 'success', errorMessage: null });
            }
            catch (err) {
                this.setState({ errorMessage: err.message });
                logError(err);
            }
            finally {
                this.setState({ pending: false });
            }
        };
        this.handlePasswordForm = async () => {
            const { translate } = this.context;
            if (this.state.pending)
                return;
            const { password, passwordRepeat, passwordResetKey } = this.state;
            if (password !== passwordRepeat)
                throw new Error(translate('error_message_password_different'));
            if (!isValidPassword(password))
                throw new Error(translate('error_message_password_invalid'));
            await userPasswordReset(passwordResetKey, password);
            try {
                this.setState({ pending: true });
                this.props.history.push(paths.login);
            }
            catch (err) {
                this.setState({ errorMessage: err.message || translate('error_message_password_invalid') });
                logError(err);
            }
            finally {
                this.setState({ pending: false });
            }
        };
        this.handleEmailInput = (email) => this.setState({ email });
        this.handlePasswordInput = (password) => this.setState({ password });
        this.handlePasswordRepeatInput = (passwordRepeat) => this.setState({ passwordRepeat });
        const { query } = parseUrl(props.location.search);
        this.state = {
            email: '',
            password: '',
            passwordRepeat: '',
            passwordResetKey: query.key || '',
            pending: false,
            errorMessage: null,
            viewState: query.key
                ? 'enter_password'
                : 'enter_email',
        };
    }
    render() {
        const { viewState, email, password, passwordRepeat, errorMessage, } = this.state;
        const isEmail = isEmailAdress(this.state.email);
        const validPassword = password === passwordRepeat && isValidPassword(password);
        const { translate } = this.context;
        return (React.createElement(Page, null,
            React.createElement(Container, null,
                React.createElement(Form.Container, null,
                    React.createElement(Form.Heading, null, translate('emailreset_heading'))),
                React.createElement(Steps, null,
                    React.createElement(Steps.Item, { label: "E-mail", active: viewState === 'enter_email' }),
                    React.createElement(Steps.Item, { label: "Link in je inbox", active: viewState === 'success' }),
                    React.createElement(Steps.Item, { label: "Wachtwoord", active: viewState === 'enter_password' })),
                (() => {
                    if (viewState === 'enter_email') {
                        return (React.createElement(Form, { onSubmit: this.handleEmailSubmit },
                            errorMessage && React.createElement(Form.ErrorMessage, null, errorMessage),
                            React.createElement(Form.Text, null, translate('emailreset_email_enter_text')),
                            React.createElement(Input, { value: email, onChange: this.handleEmailInput, error: !isEmail }),
                            React.createElement("br", null),
                            React.createElement(Button, { disabled: !isEmail, theme: "primary", onClick: this.handleEmailSubmit, text: translate('send_mail') })));
                    }
                    if (viewState === 'success') {
                        return (React.createElement(Form.Container, null,
                            React.createElement(Form.Text, null, translate('emailreset_email_success_text'))));
                    }
                    if (viewState === 'enter_password') {
                        return (React.createElement(Form, { onSubmit: this.handlePasswordForm },
                            errorMessage && React.createElement(Form.ErrorMessage, null, errorMessage),
                            React.createElement(Input, { label: translate('emailreset_inputlabel_password'), type: "password", placeholder: translate('emailreset_inputplaceholder_password'), value: password, onChange: this.handlePasswordInput }),
                            React.createElement(Input, { type: "password", placeholder: translate('emailreset_inputplaceholder_repeat_password'), value: passwordRepeat, onChange: this.handlePasswordRepeatInput, error: passwordRepeat !== password && translate('emailreset_errorlabel_repeat_password') }),
                            React.createElement(Form.Text, { small: true }, translate('emailreset_password_info')),
                            React.createElement(Button, { disabled: !validPassword, theme: "primary", onClick: this.handlePasswordForm, text: translate('change_password') })));
                    }
                    return null;
                })())));
    }
}
EmailResetPage.contextType = LocaleContext;
export default withRouter(EmailResetPage);
