import * as React from 'react';
import { VideoPlayerUi, } from '@source/view/components';
import createMeisterPlayer from './MeisterPlayer';
import VideoPlayerLoading from './VideoPlayer.Loading';
import ChannelSelect from '../VideoPlayerUi/VideoPlayerUi.ChannelSelect';
const HIDE_UI_TIMEOUT = 5000; // 5 seconds
async function pollChromecastAvailable() {
    if (!window.chrome.cast || !window.chrome.cast.isAvailable) {
        await new Promise(r => setTimeout(r, 1000));
        return pollChromecastAvailable();
    }
    return true;
}
const MeisterPlayerContext = React.createContext(null);
const MeisterPlayerContainer = ({ children }) => {
    // state
    const [meisterPlayerInstance, setMeisterPlayerInstance] = React.useState(null);
    const [buffering, setBuffering] = React.useState(false);
    const [playing, setPlaying] = React.useState(false);
    const containerEl = React.useMemo(() => document.createElement('div'), []);
    // meister handlers
    const handleBuffering = React.useCallback(() => setBuffering(true), []);
    const handleBuffered = React.useCallback(() => setBuffering(false), []);
    const handlePlayerPlaying = React.useCallback(() => setPlaying(true), []);
    const handlePlayerPause = React.useCallback(() => setPlaying(false), []);
    // meister functions
    const playVideo = React.useCallback(() => meisterPlayerInstance && meisterPlayerInstance.play(), [meisterPlayerInstance]);
    const pauseVideo = React.useCallback(() => meisterPlayerInstance && meisterPlayerInstance.pause(), [meisterPlayerInstance]);
    const togglePlay = React.useCallback(() => meisterPlayerInstance && meisterPlayerInstance.playing ? pauseVideo() : playVideo(), [meisterPlayerInstance]);
    const setVolume = React.useCallback((volume) => {
        if (meisterPlayerInstance) {
            meisterPlayerInstance.volume = volume / 100;
            meisterPlayerInstance.muted = false;
            if (volume === 0) {
                meisterPlayerInstance.muted = true;
            }
        }
    }, [meisterPlayerInstance]);
    const setItem = React.useCallback((videoInfo) => {
        if (meisterPlayerInstance && videoInfo) {
            try {
                meisterPlayerInstance.setItem(videoInfo);
                meisterPlayerInstance.load();
            }
            catch (err) {
                console.error(err);
            }
        }
    }, [meisterPlayerInstance]);
    const mountElement = React.useCallback((el) => {
        el.appendChild(containerEl);
        return () => {
            el.removeChild(containerEl);
        };
    }, []);
    React.useEffect(() => {
        createMeisterPlayer(containerEl).then((instance) => {
            setMeisterPlayerInstance(instance);
            instance.on('itemLoaded', () => {
                // when the item is loaded, add `playsinline` for ios
                const mediaElement = instance && instance.playerPlugin && instance.playerPlugin.mediaElement;
                if (mediaElement) {
                    mediaElement.setAttribute('playsinline', '');
                    mediaElement.setAttribute('webkit-playsinline', '');
                }
                instance.play();
            });
            instance.on('playerPlaying', handlePlayerPlaying);
            instance.on('playerPause', handlePlayerPause);
            instance.on('playerBuffering', handleBuffering);
            instance.on('playerBufferedEnough', handleBuffered);
        });
        return () => {
            if (meisterPlayerInstance) {
                meisterPlayerInstance.destroy();
            }
        };
    }, []);
    const context = React.useMemo(() => ({
        instance: meisterPlayerInstance,
        setVolume,
        playVideo,
        pauseVideo,
        togglePlay,
        setItem,
        playing,
        buffering: !meisterPlayerInstance && buffering,
        mountElement,
    }), [meisterPlayerInstance, playing, buffering, mountElement]);
    return (React.createElement(MeisterPlayerContext.Provider, { value: context }, children));
};
class MeisterPlayerComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.hideControlsTimeout = HIDE_UI_TIMEOUT;
        this.hideUITimeoutId = null;
        this.mounted = true;
        this.state = {
            hideControls: false,
            fullscreen: false,
            meisterLoaded: false,
        };
        this.meisterPlayerRef = React.createRef();
        this.meisterInstanceDestroy = null;
        this.setFullscreen = (isFullscreen) => {
            if (this.context) {
                // @ts-ignore
                this.context.instance.fullscreenController.toggleFullscreen();
            }
        };
        this.handleMouseMove = () => {
            this.hideUITimeoutId && clearTimeout(this.hideUITimeoutId);
            this.showControls();
            this.hideUITimeoutId = window.setTimeout(this.hideControls, this.hideControlsTimeout);
        };
        this.handleKeyboardPress = (ev) => {
            switch (ev.keyCode) {
                case 75:
                case 32:
                    // handle play/pauze
                    // this.togglePlay();
                    break;
                case 27:
                    // handle fullscreen exit
                    this.setFullscreen(false);
                    break;
                case 70:
                    // handle fullscreen activate
                    this.setFullscreen(true);
                    break;
                case 38:
                    // handle prev channel
                    this.props.handlePrevChannel && this.props.handlePrevChannel();
                    break;
                case 40:
                    // handle next channel
                    this.props.handleNextChannel && this.props.handleNextChannel();
                    break;
                case 77:
                    // handle mute
                    this.props.handleMute && this.props.handleMute();
                    break;
                case 49:
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(1);
                    break;
                case 50:
                    // handle number 2
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(2);
                    break;
                case 51:
                    // handle number 3
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(3);
                    break;
                case 52:
                    // handle number 4
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(4);
                    break;
                case 53:
                    // handle number 5
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(5);
                    break;
                case 54:
                    // handle number 6
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(6);
                    break;
                case 55:
                    // handle number 7
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(7);
                    break;
                case 56:
                    // handle number 8
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(8);
                    break;
                case 57:
                    // handle number 9
                    this.props.handleNumberedChannel && this.props.handleNumberedChannel(9);
                    break;
            }
        };
        this.showControls = () => this.mounted && this.setState({ hideControls: false });
        this.hideControls = () => this.mounted && this.setState({ hideControls: true });
    }
    async componentDidMount() {
        if (this.meisterPlayerRef.current && this.context) {
            this.meisterInstanceDestroy = this.context.mountElement(this.meisterPlayerRef.current);
        }
        this.setItem();
        document.addEventListener('keydown', this.handleKeyboardPress);
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.meisterInstanceDestroy)
            this.meisterInstanceDestroy();
        document.removeEventListener('keydown', this.handleKeyboardPress);
    }
    componentDidUpdate(prevProps) {
        if (this.context) {
            if (this.props.videoInfo !== prevProps.videoInfo) {
                this.setItem();
            }
            if (this.props.volume !== prevProps.volume) {
                this.context.setVolume(this.props.volume);
            }
        }
    }
    setItem() {
        if (this.context && this.props.videoInfo) {
            this.context.setItem(Object.assign({}, this.props.videoInfo));
        }
    }
    render() {
        if (this.context === null)
            throw new Error('Need to use the VideoPlayer.Controller higher up the tree');
        const { currentProgram, nextProgram, currentTime, channel, handleBack, isHidden, } = this.props;
        const containerStyles = {
            display: isHidden ? 'none' : 'block',
        };
        return (React.createElement("div", { style: containerStyles },
            React.createElement(VideoPlayerUi, { playing: this.context.playing, buffering: this.context.buffering, playerRef: this.meisterPlayerRef, controlBar: true, handleBackButton: handleBack, hideControls: this.state.hideControls, onMouseMove: this.handleMouseMove, fullscreen: this.state.fullscreen, handleFullscreen: this.setFullscreen, handlePlay: this.context.playVideo, handlePause: this.context.pauseVideo, bottomBar: React.createElement(VideoPlayerUi.Info, { title: currentProgram ? currentProgram.name : undefined, description: currentProgram ? currentProgram.description : undefined, startTime: currentProgram ? currentProgram.startTime : undefined, endTime: currentProgram ? currentProgram.endTime : undefined, currentChannel: channel, nextStartTime: nextProgram ? nextProgram.startTime : undefined, nextEndTime: nextProgram ? nextProgram.endTime : undefined, nextTitle: nextProgram ? nextProgram.name : undefined, currentTime: currentTime }) })));
    }
}
MeisterPlayerComponent.contextType = MeisterPlayerContext;
function VideoPlayer(props) {
    const { loadingMessage, loadingTitle, loadingSpinner } = props;
    const [loading, setLoading] = React.useState(null);
    const chomecastContextRef = React.useRef(null);
    const handleChromecastSessionStateChange = React.useCallback((ev) => {
        switch (ev.sessionState) {
            case window.cast.framework.SessionState.SESSION_STARTING:
                setLoading({
                    title: 'Chromecast',
                    message: 'session starting',
                    spinner: true,
                    type: 'chrome_cast',
                });
                break;
            case window.cast.framework.SessionState.SESSION_RESUMED:
            case window.cast.framework.SessionState.SESSION_STARTED:
                setLoading({
                    title: 'Chromecast',
                    message: 'session running',
                    spinner: false,
                    type: 'chrome_cast',
                });
                break;
            case window.cast.framework.SessionState.SESSION_ENDED:
                setLoading(null);
                break;
        }
    }, []);
    React.useEffect(() => {
        pollChromecastAvailable()
            .then(() => {
            if (window.cast) {
                const chromecastContext = window.cast.framework.CastContext.getInstance();
                chromecastContext.addEventListener(window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED, handleChromecastSessionStateChange);
                chomecastContextRef.current = chromecastContext;
            }
        });
        return () => {
            if (chomecastContextRef.current) {
                chomecastContextRef.current.removeEventListener(window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED, handleChromecastSessionStateChange);
            }
        };
    }, []);
    const isLoading = !!(loading || loadingMessage || loadingTitle || loadingSpinner);
    return (React.createElement(React.Fragment, null,
        isLoading && loading && loading.type === 'chrome_cast' && (React.createElement(React.Fragment, null,
            React.createElement("div", { style: {
                    position: 'fixed',
                    top: '2em',
                    right: '3em',
                    width: '2em',
                    height: '2em',
                    color: 'white',
                } },
                React.createElement("google-cast-launcher", null)),
            React.createElement("div", { style: {
                    position: 'fixed',
                    bottom: '1em',
                    left: '1em',
                } },
                React.createElement(ChannelSelect, { channelLogo: props.channel ? props.channel.logoImageUrl : undefined })))),
        isLoading && (React.createElement(VideoPlayerLoading, Object.assign({ title: loadingTitle, message: loadingMessage, withSpinner: loadingSpinner }, loading))),
        React.createElement(MeisterPlayerComponent, Object.assign({}, props, { isHidden: isLoading }))));
}
VideoPlayer.Container = MeisterPlayerContainer;
export default VideoPlayer;
