import * as React from 'react';
import cx from 'classnames';
import ToastMessageItem from './ToastMessage.Item';
import styles from './ToastMessage.scss';
export default class ToastMessageContainer extends React.Component {
    render() {
        const { className, children } = this.props;
        return (React.createElement("div", { className: cx(styles.container, className) }, children && children[0]));
    }
}
ToastMessageContainer.Item = ToastMessageItem;
