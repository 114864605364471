import * as React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Image, Button } from '..';
import { formatToHourString } from '@source/core/utils/formatDate';
import styles from './HeroHighlight.scss';
import paths from '@source/core/constants/paths';
const HeroHighlight = ({ backgroundImage, channelLogo, channelName, channelId, subtext, title, startTime, endTime, className, }) => {
    return (React.createElement(Image, { src: backgroundImage, render: ({ loading }) => {
            const backgroundStyle = {
                backgroundImage: (backgroundImage && !loading) ? `url("${backgroundImage}")` : undefined,
            };
            return (React.createElement("div", { className: cx(styles.container, className) },
                React.createElement("div", { className: styles.content },
                    React.createElement("div", { className: styles.contentInner },
                        React.createElement("div", { className: styles.subtext }, subtext),
                        React.createElement("div", { className: styles.title }, title),
                        React.createElement("div", { className: styles.metaInfo },
                            React.createElement("img", { className: styles.logoImage, src: channelLogo, alt: channelName || 'logo' }),
                            (startTime && endTime) && React.createElement("div", { className: styles.timestamp },
                                formatToHourString(startTime),
                                " - ",
                                formatToHourString(endTime))),
                        (channelId && channelName) && React.createElement(Button, { as: Link, iconName: "play", theme: "primary", to: paths.video(channelId, channelName), text: "Kijk nu" }))),
                !loading && React.createElement("div", { className: styles.containerImage, style: backgroundStyle })));
        } }));
};
export default HeroHighlight;
