var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import classnames from 'classnames';
import { TVWatch, TVWatchActive, TVGuide, TVGuideActive, Settings, SettingsActive, Search, Clear, Profile, ChevronDown, Play, CheckMark, EyeShow, EyeHide, Chromecast, BackArrow, FullscreenOpen, FullscreenClose, Close, VolumeHigh, VolumeOff, VolumeMedium, VolumeLow, ArrowLeft, ArrowRight, ArrowDown, ArrowDownSmall, ArrowUp, Logout, } from './icons';
import styles from './Icon.scss';
const defaultSize = {
    width: 30,
    height: 30,
};
const icons = {
    tvWatch: {
        states: {
            default: React.createElement(TVWatch, null),
            active: React.createElement(TVWatchActive, null),
        },
        width: 32,
        height: 32,
    },
    tvGuide: {
        states: {
            default: React.createElement(TVGuide, null),
            active: React.createElement(TVGuideActive, null),
        },
        width: 32,
        height: 32,
    },
    settings: {
        states: {
            default: React.createElement(Settings, null),
            active: React.createElement(SettingsActive, null),
        },
        width: 32,
        height: 32,
    },
    search: {
        states: {
            default: React.createElement(Search, null),
        },
    },
    clear: {
        states: {
            default: React.createElement(Clear, null),
        },
    },
    profile: {
        states: {
            default: React.createElement(Profile, null),
        },
        width: 32,
        height: 32,
    },
    'chevron-down': {
        states: {
            default: React.createElement(ChevronDown, null),
        },
        width: 9,
        height: 5,
    },
    play: {
        states: {
            default: React.createElement(Play, null),
        },
    },
    checkMark: {
        states: {
            default: React.createElement(CheckMark, null),
        },
        width: 32,
        height: 32,
    },
    eyeShow: {
        states: {
            default: React.createElement(EyeShow, null),
        },
        width: 22,
        height: 16,
    },
    eyeHide: {
        states: {
            default: React.createElement(EyeHide, null),
        },
        width: 22,
        height: 16,
    },
    chromecast: {
        states: {
            default: React.createElement(Chromecast, null),
        },
        width: 32,
        height: 32,
    },
    volumeHigh: {
        states: {
            default: React.createElement(VolumeHigh, null),
        },
        width: 32,
        height: 32,
    },
    volumeMedium: {
        states: {
            default: React.createElement(VolumeMedium, null),
        },
        width: 32,
        height: 32,
    },
    volumeLow: {
        states: {
            default: React.createElement(VolumeLow, null),
        },
        width: 32,
        height: 32,
    },
    volumeOff: {
        states: {
            default: React.createElement(VolumeOff, null),
        },
        width: 32,
        height: 32,
    },
    backArrow: {
        states: {
            default: React.createElement(BackArrow, null),
        },
        width: 32,
        height: 32,
    },
    fullscreenOpen: {
        states: {
            default: React.createElement(FullscreenOpen, null),
        },
        width: 32,
        height: 32,
    },
    fullscreenClose: {
        states: {
            default: React.createElement(FullscreenClose, null),
        },
        width: 32,
        height: 32,
    },
    close: {
        states: {
            default: React.createElement(Close, null),
        },
        width: 32,
        height: 32,
    },
    arrowLeft: {
        states: {
            default: React.createElement(ArrowLeft, null),
        },
        width: 32,
        height: 32,
    },
    arrowRight: {
        states: {
            default: React.createElement(ArrowRight, null),
        },
        width: 32,
        height: 32,
    },
    arrowDown: {
        states: {
            default: React.createElement(ArrowDown, null),
        },
        width: 32,
        height: 32,
    },
    arrowDownSmall: {
        states: {
            default: React.createElement(ArrowDownSmall, null),
        },
        width: 9,
        height: 5,
    },
    arrowUp: {
        states: {
            default: React.createElement(ArrowUp, null),
        },
        width: 32,
        height: 32,
    },
    logout: {
        states: {
            default: React.createElement(Logout, null),
        },
        width: 32,
        height: 32,
    },
};
const getIcon = (name) => icons[name];
const Icon = (_a) => {
    var { iconName, className, active, width, height } = _a, props = __rest(_a, ["iconName", "className", "active", "width", "height"]);
    const icon = getIcon(iconName);
    if (!icon)
        return null;
    const { width: iconWidth, height: iconHeight, states } = icon;
    const iconPath = states[active ? 'active' : 'default'];
    const w = width || iconWidth || defaultSize.width;
    const h = height || iconHeight || defaultSize.height;
    return (React.createElement("svg", Object.assign({}, props, { width: w, height: h, className: classnames(styles.icon, className), viewBox: `0 0 ${w} ${h}` }), iconPath));
};
Icon.defaultProps = {
    width: 0,
    height: 0,
    active: false,
};
export default Icon;
