import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Input } from '@source/view/components';
import { LocaleContext } from '@source/view/enhancers/Locale';
import { userChangePassword } from '@source/core/services/vinson';
import * as user from '@source/core/store/user';
import isValidPassword from '@source/core/utils/isValidPassword';
class ChangePasswordModal extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            pending: false,
            hasSubmitOnce: false,
            errorMessage: null,
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
        };
        this.handleOldPasswordChange = (oldPassword) => this.setState({ oldPassword });
        this.handleNewPasswordChange = (newPassword) => this.setState({ newPassword });
        this.handleNewPasswordRepeatChange = (newPasswordRepeat) => this.setState({ newPasswordRepeat });
        this.handleExit = () => {
            if (!this.state.pending && this.props.handleExit) {
                this.props.handleExit();
            }
        };
        this.validateForm = () => {
            const { oldPassword, newPassword, newPasswordRepeat } = this.state;
            // it has an old passworld
            if (!oldPassword)
                return false;
            // new password is valid
            if (!isValidPassword(newPassword))
                return false;
            // new passwords did not match
            if (newPassword !== newPasswordRepeat)
                return false;
            // else, it is valid!
            return true;
        };
        this.handleSubmit = async () => {
            if (this.state.pending)
                return;
            const { translate } = this.context;
            try {
                this.setState({ pending: true, hasSubmitOnce: true });
                const isValid = this.validateForm();
                if (isValid) {
                    const response = await userChangePassword(this.props.accessToken, this.state.oldPassword, this.state.newPassword);
                    this.setState({ errorMessage: null });
                    this.props.authenticate(response);
                    this.props.handleExit();
                }
            }
            catch (err) {
                this.setState({ errorMessage: translate('error_message_password_incorrect_password') });
            }
            finally {
                this.setState({ pending: false });
            }
        };
    }
    render() {
        const { translate } = this.context;
        const { hasSubmitOnce, oldPassword, newPassword, newPasswordRepeat, errorMessage } = this.state;
        return (React.createElement(Modal, { handleExit: this.handleExit },
            React.createElement(Form, { onSubmit: this.handleSubmit, fullWidth: true },
                React.createElement(Form.Heading, null, translate('change_password')),
                errorMessage && React.createElement(Form.ErrorMessage, null, errorMessage),
                React.createElement(Input, { type: "password", label: translate('old_password'), value: oldPassword, onChange: this.handleOldPasswordChange }),
                React.createElement("br", null),
                React.createElement(Input, { type: "password", label: translate('new_password'), value: newPassword, onChange: this.handleNewPasswordChange, error: hasSubmitOnce && !isValidPassword(newPassword) && translate('error_message_password_invalid') }),
                React.createElement(Input, { type: "password", label: translate('new_password_repeat'), value: newPasswordRepeat, onChange: this.handleNewPasswordRepeatChange, error: hasSubmitOnce && newPassword !== newPasswordRepeat && translate('error_message_password_different') }),
                React.createElement("br", null),
                React.createElement(Form.Row, null,
                    React.createElement("button", { onClick: this.handleExit, className: Form.rowFillClassName }, translate('cancel')),
                    React.createElement(Button, { theme: "primary", onClick: this.handleSubmit, text: translate('change_password') })))));
    }
}
ChangePasswordModal.contextType = LocaleContext;
export default connect(null, { authenticate: user.authenticate })(ChangePasswordModal);
