import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getToken } from '@source/core/store/user';
import { fetchProgramDetails } from '@source/core/services/vinson';
import styles from './ProgramDetail.scss';
import { Icon } from '@source/view/components';
import Container from '@source/view/components/Container';
import Button from '@source/view/components/Button';
import Timebar from '@source/view/components/Timebar';
import Spinner from '@source/view/components/Spinner';
import paths from '@source/core/constants/paths';
import { formatToHourString } from '@source/core/utils/formatDate';
import { getCurrentPlayingProgram, getCurrenttime } from '@source/core/store/channel';
import { LocaleContext } from '@source/view/enhancers/Locale';
import ShowMore from '../ShowMore';
function mapState(state, props) {
    const found = props.channel.id && getCurrentPlayingProgram(state, props.channel.id);
    return {
        currentProgram: found ? found.currentProgram : null,
        currentTime: getCurrenttime(state),
        userToken: getToken(state),
    };
}
class ProgramDetail extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            pending: false,
            details: null,
            showMore: false,
        };
        this.handleCloseClick = () => {
            if (this.props.onCloseClick) {
                this.props.onCloseClick();
            }
        };
        this.showMore = () => this.setState({ showMore: true });
    }
    componentDidMount() {
        this.fetchDetails();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.program.id !== this.props.program.id) {
            this.fetchDetails();
            this.setState({ showMore: false });
        }
    }
    async fetchDetails() {
        try {
            this.setState({ pending: true });
            if (this.props.userToken) {
                const [details] = await Promise.all([
                    fetchProgramDetails(this.props.userToken, this.props.program.id),
                    new Promise(r => setTimeout(r, 500)),
                ]);
                this.setState({ details });
            }
        }
        catch (err) { }
        finally {
            this.setState({ pending: false });
        }
    }
    render() {
        const { channel, currentProgram, program, currentTime } = this.props;
        const { details, pending } = this.state;
        const isCurrentlyPlaying = (currentProgram && currentProgram.id === program.id);
        const containerClasses = cx(styles.container, {
            [styles.hasHiddenText]: !this.state.showMore,
        });
        const imageStyles = {
            backgroundImage: (details && details.imageUrlPortrait) ? `url("${details.imageUrlPortrait}")` : undefined,
        };
        return (React.createElement("div", { className: containerClasses },
            React.createElement(Container, { className: styles.containerInner }, (details && !pending) ? (React.createElement(React.Fragment, null,
                React.createElement("button", { className: styles.containerClose, onClick: this.handleCloseClick },
                    React.createElement(Icon, { iconName: "close" })),
                React.createElement("div", { className: styles.containerImage },
                    React.createElement("div", { className: styles.image, style: imageStyles })),
                React.createElement("div", { className: styles.containerInfo },
                    React.createElement("div", { className: styles.info },
                        React.createElement("div", { className: styles.infoTitle }, details.name),
                        React.createElement("div", { style: { display: 'flex' } }),
                        (isCurrentlyPlaying && currentProgram) && (React.createElement(Timebar, { noText: true, className: styles.infoTimebar, startTime: currentProgram.startTime, endTime: currentProgram.endTime, currentTime: currentTime })),
                        React.createElement("div", { className: styles.infoContent },
                            isCurrentlyPlaying && (React.createElement(Button, { as: Link, iconName: "play", to: paths.video(channel.id, channel.name), text: "Kijk nu", theme: "primary" })),
                            React.createElement("img", { className: styles.infoLogo, src: channel.logoImageUrl }),
                            React.createElement("span", { className: styles.infoTime },
                                formatToHourString(program.startTime),
                                " - ",
                                formatToHourString(program.endTime))),
                        React.createElement(ShowMore, { className: styles.infoDescription, text: details.description, lines: 3 }))))) : (React.createElement(Spinner, { className: styles.spinner })))));
    }
}
ProgramDetail.contextType = LocaleContext;
export default connect(mapState)(ProgramDetail);
