import { createAction, createThunkAction } from './utils';
import * as vinson from '../services/vinson';
import * as notification from './notifications';
import persistReducer from './persistReducer';
import { logError } from '../utils/logger';
/*
    Selectors
------------------------------------------ */
// #region selectors
export const getAuth = (state) => state.user;
export const getToken = (state) => getAuth(state).token;
export const getPending = (state) => getAuth(state).pending;
export const getUserEmail = (state) => getAuth(state).email;
export const getIsActivated = (state) => getAuth(state).activated;
export const getSubscriptionEndDate = (state) => getAuth(state).subscriptionEndDate;
export const getIsSubscribed = (state) => getAuth(state).subscribed;
export const getPersist = (state) => getAuth(state).persist;
export const getMute = (state) => getAuth(state).volumeMute;
export const getVolume = (state) => getMute(state) ? 0 : getAuth(state).volume;
export const isAuthenticated = (state) => {
    const { activated, subscribed, token } = getAuth(state);
    return !!(activated && subscribed && token);
};
// #endregion
/*
    Action Types & Action creators
------------------------------------------ */
// #region actions
var actionTypes;
(function (actionTypes) {
    actionTypes["AUTH_PENDING"] = "auth/AUTH_PENDING";
    actionTypes["AUTH_RESOLVED"] = "auth/AUTH_RESOLVED";
    actionTypes["SET_USER_INFO"] = "auth/SET_USER_INFO";
    actionTypes["LOGOUT"] = "auth/LOGOUT";
    actionTypes["SET_VOLUME"] = "auth/SET_VOLUME";
    actionTypes["SET_PERSIST"] = "auth/SET_PERSIST";
    actionTypes["SET_MUTE"] = "auth/SET_MUTE";
})(actionTypes || (actionTypes = {}));
const authPending = () => createAction(actionTypes.AUTH_PENDING);
const authResolved = () => createAction(actionTypes.AUTH_RESOLVED);
export const authenticate = (payload) => createAction(actionTypes.SET_USER_INFO, payload);
const logout = () => createAction(actionTypes.LOGOUT);
export const changeVolume = (volume) => createAction(actionTypes.SET_VOLUME, volume);
export const setMute = (p) => createAction(actionTypes.SET_MUTE, p);
export const toggleMute = () => createThunkAction((dispatch, getState) => {
    dispatch(setMute(!getMute(getState())));
});
export const setPersist = (persist) => createAction(actionTypes.SET_PERSIST, persist);
export const logoutUser = () => createThunkAction(async (dispatch) => {
    await vinson.userSignOut();
    dispatch(logout());
    dispatch(notification.addNotification('logout_success'));
});
export const loginUser = (username, password) => createThunkAction(async (dispatch) => {
    try {
        dispatch(authPending());
        const response = await vinson.userSignIn(username, password);
        if (!response.activated) {
            logError('account was not activated');
            throw new Error('Account is not activated');
        }
        dispatch(authenticate(response));
        dispatch(notification.addNotification('login_success'));
        dispatch(authResolved());
        return response;
    }
    catch (err) {
        logError(err);
        dispatch(authResolved());
        return Promise.reject(err);
    }
});
export const checkUser = () => createThunkAction(async (dispatch, getState) => {
    try {
        const userToken = getToken(getState());
        if (!userToken)
            throw new Error('no user token found');
        dispatch(authPending());
        const response = await vinson.userStatus(userToken);
        if (!response.activated) {
            logError('account was not activated');
            throw new Error('Account is not activated');
        }
        dispatch(authenticate(response));
        return response;
    }
    catch (err) {
        dispatch(notification.addNotification(err.message, 'error'));
        return Promise.reject(err);
    }
    finally {
        dispatch(authResolved());
    }
});
export const extendUser = (key) => createThunkAction(async (dispatch, getState) => {
    try {
        const userToken = getToken(getState());
        if (!userToken)
            throw new Error('no user token found');
        dispatch(authPending());
        const response = await vinson.subscriptionActivate(key, userToken);
        dispatch(authenticate(response));
        return response;
    }
    catch (err) {
        return Promise.reject(err);
    }
    finally {
        dispatch(authResolved());
    }
});
export const activatedUser = (key) => createThunkAction(async (dispatch) => {
    try {
        dispatch(authPending());
        const response = await vinson.userActivate(key);
        dispatch(authenticate(response));
    }
    catch (err) {
        dispatch(notification.addNotification(err.message, 'error'));
        return Promise.reject(err);
    }
    finally {
        dispatch(authResolved());
    }
});
const initialState = {
    pending: true,
    token: null,
    subscribed: false,
    subscriptionEndDate: 0,
    subscriptionAlmostExpired: true,
    activated: false,
    email: null,
    volume: 50,
    volumeMute: false,
    persist: true,
    providerName: null,
    providerEmail: null,
};
export const reducer = persistReducer('user', (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_PENDING: return Object.assign({}, state, { pending: true });
        case actionTypes.AUTH_RESOLVED: return Object.assign({}, state, { pending: false });
        case actionTypes.SET_VOLUME: return Object.assign({}, state, { volume: action.payload, volumeMute: false });
        case actionTypes.SET_PERSIST: return Object.assign({}, state, { persist: action.payload });
        case actionTypes.SET_USER_INFO: return Object.assign({}, state, action.payload);
        case actionTypes.SET_MUTE: return Object.assign({}, state, { volumeMute: action.payload });
        case actionTypes.LOGOUT: return Object.assign({}, initialState, { pending: false });
        default: return state;
    }
}, {
    whitelist: ['token', 'volume', 'volumeMute', 'persist'],
    saveOnKey: 'persist',
});
// #endregion
