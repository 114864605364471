import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import $ from './Modal.scss';
class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.mountElement = document.createElement('div');
        document.body.appendChild(this.mountElement);
    }
    componentWillUnmount() {
        document.body.removeChild(this.mountElement);
    }
    render() {
        return ReactDOM.createPortal((React.createElement("div", { className: $.container },
            React.createElement(Helmet, null,
                React.createElement("body", { className: "is-fixed" })),
            React.createElement("div", { className: cx($.modal, $.containerModal) }, this.props.children),
            React.createElement("div", { className: $.containerBackground, onClick: this.props.handleExit }))), this.mountElement);
    }
}
export default Modal;
