import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Logo, Container, Header, Menu, Footer } from '@source/view/components';
import { getMediaQuery } from '@source/core/store/app';
import styles from './Page.scss';
import { mediaQueryIsActive } from '@source/core/mediaQuery';
import { Link } from 'react-router-dom';
import paths from '@source/core/constants/paths';
const mapState = (state) => ({
    mediaQuery: getMediaQuery(state),
});
class Page extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    render() {
        const { pageType, title, renderAside, disableHeaderSpacing, disablePageSpacing, className } = this.props;
        const isMobile = mediaQueryIsActive(this.props.mediaQuery, 'small');
        const pageClasses = cx(styles.page, className, {
            [styles.isDashboard]: pageType === 'dashboard',
            [styles.hasNoHeaderSpacing]: disableHeaderSpacing,
            [styles.hasNoPageSpacing]: disablePageSpacing,
        });
        return (React.createElement("div", { className: pageClasses },
            pageType === 'dashboard' && (React.createElement("div", { className: styles.pageAside },
                React.createElement(Menu, null))),
            React.createElement("div", { className: styles.pageContent },
                React.createElement("div", { className: styles.pageInner },
                    (() => {
                        if (pageType === 'default') {
                            return (React.createElement("header", { className: cx(styles.header, styles.pageHeader) },
                                React.createElement("div", { className: styles.headerMain },
                                    React.createElement(Container, { className: styles.headerContainer },
                                        React.createElement(Link, { to: paths.login },
                                            React.createElement(Logo, { className: styles.headerLogo })),
                                        (!isMobile && renderAside) && (React.createElement("div", { className: styles.headerAside }, renderAside)))),
                                (isMobile && renderAside) && (React.createElement(Container, null,
                                    React.createElement("div", { className: styles.headerAside }, renderAside)))));
                        }
                        if (pageType === 'dashboard') {
                            return (React.createElement(Header, { className: cx(styles.header, styles.pageHeader), pageTitle: title }));
                        }
                        return null;
                    })(),
                    React.createElement("main", { className: cx(styles.main, styles.pageMain) },
                        React.createElement("div", { className: styles.mainInner },
                            this.props.children,
                            pageType === 'dashboard' && (React.createElement(Footer, null))))),
                this.props.renderStickyBottom && (React.createElement("div", { className: styles.stickyBottom }, this.props.renderStickyBottom)))));
    }
}
Page.defaultProps = {
    pageType: 'default',
};
export default connect(mapState)(Page);
