import * as React from 'react';
import cx from 'classnames';
import InputRange from 'react-input-range';
import styles from './RangeSlider.scss';
const classes = {
    disabledInputRange: styles.isDisabled,
    inputRange: styles.container,
    activeTrack: styles.trackFill,
    slider: styles.trackDot,
    sliderContainer: styles.trackSlider,
    track: styles.track,
    valueLabel: styles.label,
    maxLabel: cx(styles.label, styles.maxLabel),
    minLabel: cx(styles.label, styles.minLabel),
    labelContainer: styles.labelContainer,
};
const RangeSlider = ({ minValue = 0, maxValue, value, onChange, onDragStart, onDragEnd, }) => {
    return (React.createElement(InputRange, { classNames: classes, minValue: minValue, maxValue: maxValue, value: value, onChangeStart: onDragStart, onChangeComplete: onDragEnd, onChange: (value) => {
            if (typeof value === 'number' && onChange) {
                onChange(value);
            }
        } }));
};
export default RangeSlider;
