import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Page, Container, SettingsBlock, Button } from '@source/view/components';
import ChangePasswordModal from './Settings.ChangePasswordModal';
import paths from '@source/core/constants/paths';
import { LocaleContext } from '@source/view/enhancers/Locale';
function mapState(state) {
    return {
        userEmail: state.user.email || '',
        extendTime: state.user.subscriptionEndDate,
        needsExtending: state.user.subscriptionAlmostExpired,
        accessToken: state.user.token || '',
    };
}
class SettingsPage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { extendModal: true, modal: null };
    }
    render() {
        const { translate } = this.context;
        const { userEmail, needsExtending, extendTime, } = this.props;
        return (React.createElement(Page, { title: translate('settings'), pageType: "dashboard" },
            React.createElement(Container, null,
                React.createElement(SettingsBlock, null,
                    React.createElement(SettingsBlock.Heading, { title: translate('settings_heading_account') }),
                    React.createElement(SettingsBlock.Text, { size: "label" }, translate('email')),
                    React.createElement(SettingsBlock.Row, null,
                        React.createElement(SettingsBlock.Text, null, userEmail)),
                    React.createElement(SettingsBlock.Text, { size: "label" }, translate('password')),
                    React.createElement(SettingsBlock.Row, null,
                        React.createElement(SettingsBlock.Text, null, "*********"),
                        React.createElement("button", { onClick: () => this.setState({ modal: 'change_password' }) }, translate('change_password')),
                        this.state.modal === 'change_password' && (React.createElement(ChangePasswordModal, { handleExit: () => this.setState({ modal: null }), accessToken: this.props.accessToken })))),
                React.createElement(SettingsBlock, null,
                    React.createElement(SettingsBlock.Heading, { title: translate('settings_heading_subscription') }),
                    React.createElement(SettingsBlock.Row, null,
                        React.createElement(SettingsBlock.Text, null, translate('account_valid_until', { endDate: format(extendTime * 1000, 'DD-MM-YY') }))),
                    needsExtending && (React.createElement(Button, { theme: "primary", as: Link, to: paths.extend, text: translate('extend') }))))));
    }
}
SettingsPage.contextType = LocaleContext;
export default connect(mapState)(SettingsPage);
