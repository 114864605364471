import * as React from 'react';
import cx from 'classnames';
import styles from './Dropdown.scss';
import Option from './Dropdown.Option';
const ANIMATION_DURATION = 200; // in ms
export default class Dropdown extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isExpanded: false,
            isActive: false,
        };
        this.handleEnter = () => {
            this.setState({ isExpanded: true, isActive: true });
            clearInterval(this.timeoutId);
        };
        this.handleLeave = () => {
            this.setState({ isExpanded: false });
            this.timeoutId = window.setTimeout(() => {
                this.setState({ isActive: false });
            }, ANIMATION_DURATION);
        };
    }
    render() {
        const { className, inner, expanded, align } = this.props;
        const containerClasses = cx(styles.container, className, {
            [styles.isExpanded]: expanded || this.state.isExpanded,
            [styles.isActive]: expanded || this.state.isActive,
            [styles.isAlignedRight]: align === 'right',
        });
        return (React.createElement("div", { className: containerClasses, onPointerEnter: this.handleEnter, onPointerLeave: this.handleLeave },
            inner,
            React.createElement("div", { className: styles.containerOptions }, this.props.children)));
    }
}
Dropdown.Option = Option;
