import * as React from 'react';
import { connect } from 'react-redux';
import { getCurrenttime } from '@source/core/store/channel';
import { HEIGHT_PER_MINUTE } from '@source/core/constants/sizes';
import { LocaleContext } from '@source/view/enhancers/Locale';
import $ from './SwimmingLane.scss';
import ScrollContainer from '../ScrollArea';
import getElementOffset from '@source/core/utils/getElementOffset';
const TODAY_DATE = new Date();
const MIDNIGHT_DATE = new Date(TODAY_DATE.getFullYear(), TODAY_DATE.getMonth(), TODAY_DATE.getDate()).getTime() / 1000;
function calculateHeight(currenttime) {
    const minutes = (currenttime - MIDNIGHT_DATE) / 60;
    return minutes * HEIGHT_PER_MINUTE;
}
class SwimmingLaneBar extends React.Component {
    constructor() {
        super(...arguments);
        this.scrolled = false;
        this.barRef = React.createRef();
        this.scrollTo = () => {
            const { element } = this.props.scroll;
            const barEl = this.barRef.current;
            if (!this.scrolled && element && barEl) {
                this.scrolled = true;
                const barOffset = getElementOffset(barEl);
                element.scrollTop = barOffset.top - (window.innerHeight / 2);
            }
        };
    }
    componentDidMount() {
        // force update to set refs and trigger didUpdate
        this.forceUpdate();
    }
    componentDidUpdate() {
        this.scrollTo();
    }
    render() {
        const { currenttime, headerHeight, locale } = this.props;
        const { translate } = locale;
        const barStyles = {
            top: `${calculateHeight(currenttime) + headerHeight}px`,
        };
        return (React.createElement("div", { className: $.bar, style: barStyles, ref: this.barRef },
            React.createElement("div", { className: $.barLabel }, translate('now')),
            React.createElement("div", { className: $.barLine })));
    }
}
export default connect((state) => ({
    currenttime: getCurrenttime(state),
}))((props) => {
    return (React.createElement(ScrollContainer.Context.Consumer, null, scroll => (React.createElement(LocaleContext.Consumer, null, locale => (React.createElement(SwimmingLaneBar, Object.assign({}, props, { locale: locale, scroll: scroll })))))));
});
