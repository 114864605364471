const paths = {
    home: '/',
    login: '/login',
    register: '/account/register',
    activate: '/account/activate',
    extend: '/account/extend',
    forgotPassword: '/account/forgot-password',
    guide: '/tv-guide',
    settings: '/settings',
    privacy: '/privacy',
    video(channelId, channelSlug) {
        return `/video/${channelSlug}`;
    },
    registerWithCode(activationCode) {
        return `/register/${activationCode}`;
    },
};
export default paths;
