import * as React from 'react';
const ScrollContext = React.createContext({
    scrollTo() { },
    element: null,
});
export default class ScrollContainer extends React.Component {
    constructor() {
        super(...arguments);
        this.containerRef = React.createRef();
        this.handleScrollTo = (el, position = 'top') => {
            const containerEl = this.containerRef.current;
            if (containerEl) {
                let nextScrollTop = el.offsetTop;
                if (position === 'center') {
                    nextScrollTop = (containerEl.offsetHeight / 2);
                }
                containerEl.scrollTop = nextScrollTop;
            }
        };
    }
    componentDidMount() {
        // force update so the ref is set
        this.forceUpdate();
    }
    render() {
        const { className, as: ElementType } = this.props;
        const context = {
            scrollTo: this.handleScrollTo,
            element: this.containerRef.current,
        };
        return (React.createElement(ScrollContext.Provider, { value: context },
            React.createElement("div", { className: className, ref: this.containerRef, style: this.props.style }, this.props.children)));
    }
}
ScrollContainer.Context = ScrollContext;
ScrollContainer.defaultProps = {
    as: 'div',
};
