export const getFullscreenMethod = (el) => {
    if (el.requestFullscreen) {
        return el.requestFullscreen.bind(el);
    }
    if (el.mozRequestFullScreen) {
        return el.mozRequestFullScreen.bind(el);
    }
    if (el.webkitRequestFullscreen) {
        return el.webkitRequestFullscreen.bind(el);
    }
    if (el.msRequestFullscreen) {
        return el.msRequestFullscreen.bind(el);
    }
    return null;
};
export function launchFullscreen(el) {
    const requestFullscreen = getFullscreenMethod(el);
    if (requestFullscreen !== null) {
        requestFullscreen();
    }
    else {
        throw new Error('Browser does not support fullscreen');
    }
}
export function exitFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    }
    else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    }
    else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
}
