import { createUnixTimestamp } from '../utils/unixTimestamp';
// @TODO adjust this to the correct two weeks, the 55 minutes
// are purely for testing. As the current duration of a
// subscription is 1 hour
const SUBCRIPTION_SECONDS_TILL_WARNING = 60;
export default class UserInfo {
    constructor(params) {
        const currentTime = createUnixTimestamp();
        this.token = params.token || null;
        this.subscribed = params.subscribed;
        this.subscriptionEndDate = params.subscriptionEndDate || currentTime;
        this.subscriptionAlmostExpired = (this.subscriptionEndDate - (currentTime - SUBCRIPTION_SECONDS_TILL_WARNING)) <= 0;
        this.email = params.email;
        this.activated = params.activated;
        this.providerName = params.provider || null;
        this.providerEmail = params.providerEmail || null;
    }
}
