import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import paths from '@source/core/constants/paths';
import getCurrentProgram from '@source/core/utils/getCurrentProgram';
import Icon from '../Icon';
import Timebar from '../Timebar';
import styles from './ChannelSelector.scss';
const DEFAULT_PROGRAM_NAME = 'DEFAULT_PROGRAM_NAME';
export default function ChannelSelectorItem({ channel, compact, isSelected, onItemClick, currentTime, channels, innerRef, }) {
    const { currentProgram } = getCurrentProgram(channels, channel.id, currentTime);
    const logoStyles = {
        backgroundImage: channel.logoImageUrl
            ? `url("${channel.logoImageUrl}")`
            : undefined,
    };
    const itemClasses = cx(styles.item, {
        [styles.isSelected]: isSelected,
    });
    return (React.createElement(Link, { to: paths.video(channel.id, channel.slug), className: itemClasses, key: channel.id, onClick: onItemClick, innerRef: innerRef },
        React.createElement("div", { className: styles.itemLogo, style: logoStyles }),
        compact ? (React.createElement("div", { className: styles.itemName }, channel.name)) : (React.createElement("div", { className: styles.itemInfo },
            React.createElement("div", { className: styles.itemName }, currentProgram ? currentProgram.name : DEFAULT_PROGRAM_NAME),
            React.createElement(Timebar, { compact: true, className: styles.itemTimebar, currentTime: currentTime, startTime: currentProgram ? currentProgram.startTime : undefined, endTime: currentProgram ? currentProgram.endTime : undefined }))),
        isSelected && React.createElement(Icon, { className: styles.itemCheckmark, iconName: "checkMark" })));
}
