import * as React from 'react';
import SettingsBlockHeading from './SettingsBlock.Heading';
import SettingsBlockText from './SettingsBlock.Text';
import SettingsBlockRow from './SettingsBlock.Row';
import styles from './SettingsBlock.scss';
class SettingsBlock extends React.Component {
    render() {
        const { children } = this.props;
        return (React.createElement("div", { className: styles.block }, children));
    }
}
SettingsBlock.Heading = SettingsBlockHeading;
SettingsBlock.Text = SettingsBlockText;
SettingsBlock.Row = SettingsBlockRow;
export default SettingsBlock;
