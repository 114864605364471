import * as React from 'react';
import cx from 'classnames';
import Icon from '@source/view/components/Icon/Icon';
import Image from '@source/view/components/Image';
import { LocaleContext } from '@source/view/enhancers/Locale';
import deferRender from '../../enhancers/deferRender';
import styles from './SwimmingLane.scss';
import SwimmingLaneRow from './SwimmingLane.Row';
import SwimmingLaneBar from './SwimmingLane.Bar';
import Swipable from '@source/view/components/Swipable';
function normalizeDuration(program, isEnd = false) {
    const normalizedStartTime = new Date(program.startDate.getFullYear(), program.startDate.getMonth(), 
    // Plus one so it will get the right midnight time
    program.startDate.getDate() + (isEnd ? 1 : 1)).getTime() / 1000;
    const duration = isEnd
        ? normalizedStartTime - program.startTime
        : program.endTime - normalizedStartTime;
    return duration;
}
function normalizeChannel(channel) {
    return Object.assign({}, channel, { programs: channel.programs ? channel.programs.map((program, index, programs) => {
            if (index === 0) {
                return Object.assign({}, program, { duration: normalizeDuration(program) });
            }
            if (index === (programs.length - 1)) {
                return Object.assign({}, program, { duration: normalizeDuration(program, true) });
            }
            return program;
        }) : channel.programs });
}
class SwimmingLaneContainer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            headerHeight: 0,
            itemIndex: 0,
            horizontalOffset: 0,
        };
        this.lastHeaderHeight = 0;
        this.containerRef = React.createRef();
        this.innerRef = React.createRef();
        this.headerRef = React.createRef();
        this.rowRef = React.createRef();
        this.offsetLabels = {
            0: this.context.translate('today'),
            1: this.context.translate('tomorrow'),
            2: this.context.translate('day_after_tomorrow'),
        };
        this.handleKeyDown = (event) => {
            if (event.keyCode === 37) {
                this.scrollRight();
            }
            if (event.keyCode === 39) {
                this.scrollLeft();
            }
        };
        this.getRowCount = () => {
            const containerEl = this.containerRef.current;
            const rowEl = this.rowRef.current;
            if (containerEl && rowEl) {
                return Math.floor(containerEl.offsetWidth / rowEl.offsetWidth);
            }
            return 1;
        };
        this.scrollLeft = () => {
            this.setState(state => this.calculateScrollOffset(state, this.getRowCount()));
        };
        this.scrollRight = () => {
            this.setState(state => this.calculateScrollOffset(state, this.getRowCount() * -1));
        };
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        this.calculateHeaderOffset();
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }
    componentDidUpdate() {
        const headerEl = this.headerRef.current;
        if (headerEl) {
            if (this.state.headerHeight !== headerEl.offsetHeight) {
                this.setState({
                    headerHeight: headerEl.offsetHeight,
                });
            }
        }
    }
    calculateHeaderOffset() {
        const headerEl = this.headerRef.current;
        if (headerEl) {
            this.setState({
                headerHeight: headerEl.offsetHeight,
            });
        }
    }
    calculateScrollOffset(state, addedIndex) {
        const containerEl = this.containerRef.current;
        const innerEl = this.innerRef.current;
        const rowEl = this.rowRef.current;
        if (containerEl && innerEl && rowEl) {
            const channelsLength = this.props.channelItems[0]
                ? this.props.channelItems[0].channels.length - this.getRowCount()
                : 0;
            let nextIndex = state.itemIndex + addedIndex;
            if (nextIndex < 0)
                nextIndex = 0;
            if (nextIndex > channelsLength)
                nextIndex = channelsLength;
            const nextOffset = nextIndex * rowEl.offsetWidth;
            return {
                horizontalOffset: nextOffset,
                itemIndex: nextIndex,
            };
        }
        return null;
    }
    render() {
        const { headerHeight } = this.state;
        const { channelItems, onChannelClick } = this.props;
        if (channelItems.length === 0)
            return null;
        const containerStyles = {
            '--swimmingLane-offset': `${headerHeight}px`,
        };
        const containerClasses = cx(styles.container, this.props.className);
        const offsetStyles = {
            transform: `translateX(${this.state.horizontalOffset * -1}px)`,
        };
        if (channelItems.length === 0) {
            return React.createElement("h1", null, "not enough items");
        }
        const rowsCount = this.getRowCount();
        return (React.createElement(Swipable, { handleSwipeLeft: this.scrollLeft, handleSwipeRight: this.scrollRight },
            React.createElement("div", { ref: this.containerRef, className: containerClasses, style: containerStyles },
                React.createElement("div", { className: cx(styles.containerArrows, styles.arrows) },
                    React.createElement("button", { className: styles.arrowsArrow, onClick: this.scrollRight },
                        React.createElement(Icon, { iconName: "arrowLeft" })),
                    React.createElement("button", { className: styles.arrowsArrow, onClick: this.scrollLeft },
                        React.createElement(Icon, { iconName: "arrowRight" }))),
                channelItems.map((channel, index) => {
                    const first = index === 0;
                    return (React.createElement(React.Fragment, { key: channel.offset },
                        !first && (React.createElement("div", { className: styles.divider },
                            React.createElement("div", { className: styles.dividerText }, this.offsetLabels[channel.offset]))),
                        React.createElement(SwimmingLaneBar, { headerHeight: headerHeight }),
                        React.createElement("div", { ref: first ? this.innerRef : undefined, style: offsetStyles, className: styles.containerInner }, [...channel.channels].map((item, index) => {
                            const buffer = rowsCount + 2; // buffer is the amount of visible rows plus two for the gutter
                            const currentIndex = this.state.itemIndex;
                            const activeStart = currentIndex - buffer;
                            const activeEnd = currentIndex + buffer;
                            const isVisible = index >= activeStart && index <= activeEnd;
                            return (React.createElement("div", { key: item.id, ref: first ? this.rowRef : undefined, className: styles.containerItem }, isVisible ? (React.createElement(React.Fragment, null,
                                React.createElement("header", { ref: this.headerRef, className: cx(styles.itemHeader), onClick: () => {
                                        if (this.props.handleChannelHeaderClick)
                                            this.props.handleChannelHeaderClick(item.id);
                                    } },
                                    React.createElement(Image, { src: item.logoImageUrl, render: ({ loading, src }) => {
                                            const backgroundStyles = loading ? undefined : {
                                                backgroundImage: `url("${src}")`,
                                            };
                                            return (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: styles.itemLogo, style: backgroundStyles }),
                                                React.createElement("span", { className: styles.itemTitle }, item.name),
                                                React.createElement(Icon, { className: styles.itemTitleIcon, iconName: "arrowDownSmall" })));
                                        } })),
                                React.createElement(SwimmingLaneRow
                                // only set the ref on the first item
                                , Object.assign({ 
                                    // only set the ref on the first item
                                    headerRef: first ? this.headerRef : undefined, onChannelClick: onChannelClick, selectedProgramId: this.props.selectedProgramId }, normalizeChannel(item))))) : null));
                        }))));
                }))));
    }
}
SwimmingLaneContainer.contextType = LocaleContext;
export default deferRender(SwimmingLaneContainer);
