import { GOOGLE_ANALYTICS_TRACKING_ID } from '@source/core/constants/api';
window.dataLayer = window.dataLayer || [];
window.gtag = function gtag() {
    window.dataLayer.push(arguments);
};
window.gtag('js', new Date());
export async function triggerPageChange(pathname) {
    window.gtag('config', GOOGLE_ANALYTICS_TRACKING_ID, {
        page_location: window.location.href,
        page_path: pathname,
    });
}
