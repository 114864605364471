import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Input.scss';
import Icon from '../Icon';
class Input extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showPassword: false,
            hasFocussed: false,
        };
        this.clearTextInput = () => {
            if (this.props.onChange) {
                // return an empty string
                this.props.onChange('');
            }
        };
        this.handleChange = (e) => {
            const { value } = e.target;
            // pass value to parent component
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        };
        this.togglePasswordVisibility = () => {
            this.setState({
                showPassword: !this.state.showPassword,
            });
        };
        this.handleInputBlur = () => {
            if (this.props.value) {
                this.setState({ hasFocussed: true });
            }
        };
    }
    render() {
        const { hasFocussed } = this.state;
        const { value, iconName, label, type, placeholder, linkLabel, linkHref, error, } = this.props;
        const classNames = classnames(styles.container, {
            [styles.hasLabel]: !!label,
            [styles.hasIcon]: !!iconName,
            [styles.hasLink]: !!(linkLabel && linkHref),
            [styles.hasError]: hasFocussed && error,
        });
        return (React.createElement("div", { className: classNames },
            label && (React.createElement("label", { className: styles.label, htmlFor: label }, label)),
            React.createElement("div", { className: styles.containerInner },
                React.createElement("input", { className: styles.input, onChange: this.handleChange, type: this.state.showPassword ? 'text' : type, placeholder: placeholder, value: value, onBlur: this.handleInputBlur }),
                iconName && (React.createElement(Icon, { className: styles.beforeIcon, iconName: iconName })),
                type === 'password' && (React.createElement(Icon, { className: styles.showHideIcon, iconName: this.state.showPassword ? 'eyeShow' : 'eyeHide', onClick: this.togglePasswordVisibility })),
                type !== 'password' && (React.createElement(Icon, { className: styles.clearIcon, iconName: "clear", onClick: this.clearTextInput }))),
            typeof error === 'string' && (React.createElement("div", { className: styles.errorMessage }, error)),
            linkLabel && linkHref && (React.createElement(Link, { className: styles.link, to: linkHref }, linkLabel))));
    }
}
Input.defaultProps = {
    type: 'text',
};
export default Input;
