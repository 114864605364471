import * as React from 'react';
import { getLocale } from '@source/core/services/locale';
import { logWarning } from '@source/core/utils/logger';
export const LocaleContext = React.createContext({
    languages: ['nl'],
    translate() { /* noop */ return '<<noop>>'; },
});
export class LocaleProvider extends React.Component {
    constructor(props) {
        super(props);
        this.translate = (key, data) => {
            const { locale } = this.state;
            const found = locale ? locale[key] : undefined;
            // If it is undefined, return null
            if (found === undefined) {
                if (__DEV__) {
                    logWarning(key, `[locale]: Did not found \`${key}\``);
                    console.warn();
                }
                return `<<${key}>>`;
            }
            // If it is found, and there is no data. Return it
            if (!data)
                return found;
            // If there is data and found, transform the value
            return found.replace(/\{\:(.*?)\}/g, (_, key) => {
                const value = data[key];
                return value || `<<${key}>>`;
            });
        };
        this.state = {
            languages: ['nl'],
            currentLanguage: 'nl',
            pending: false,
            locale: null,
        };
    }
    async fetchLocale() {
        const data = await getLocale('nl');
        return data;
    }
    async componentDidMount() {
        this.setState({ pending: true });
        const locale = await this.fetchLocale();
        this.setState({ locale, pending: false });
    }
    render() {
        const context = {
            translate: this.translate,
            languages: this.state.languages,
        };
        return (React.createElement(LocaleContext.Provider, { value: context }, this.props.children));
    }
}
