import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { formatToHourString } from '@source/core/utils/formatDate';
import styles from './Tile.scss';
import Icon from '../Icon';
import Image from '../Image';
const Tile = ({ title, link, time, channelLogo, image, startTime = 0, endTime = 0, currentTime = 0, isPlaceholder, }) => {
    const durationWidth = (currentTime - startTime) / (endTime - startTime) * 100;
    const durationStyles = {
        width: durationWidth > 100
            ? `100%`
            : `${durationWidth}%`,
    };
    const duration = (startTime && endTime) ? endTime - startTime : 0;
    return (React.createElement(Image, { src: image, render: ({ loading }) => {
            const isLoading = isPlaceholder !== undefined ? isPlaceholder : loading;
            const tileStyles = classnames(styles.tile, {
                [styles.isPlaceholder]: isLoading,
            });
            // set the image
            const thumbnailStyle = (!isLoading && image) ? {
                backgroundImage: `url("${image}")`,
            } : undefined;
            const logoStyle = (!isLoading && channelLogo) ? {
                backgroundImage: `url("${channelLogo}")`,
            } : undefined;
            return (React.createElement("article", { className: tileStyles },
                React.createElement(Link, { className: styles.link, to: link },
                    React.createElement("div", { className: classnames(styles.itemContainer, styles.tileThumbnail) },
                        React.createElement("div", { className: styles.thumbnail },
                            React.createElement("div", { className: styles.thumbnailImage, style: thumbnailStyle })),
                        !isLoading && (React.createElement("div", { className: styles.playButtonContainer },
                            React.createElement(Icon, { iconName: "play" }))),
                        !isLoading && (React.createElement("div", { className: styles.progressBar },
                            React.createElement("span", { className: styles.progressBarIndicator, style: durationStyles })))),
                    React.createElement("div", { className: classnames(styles.meta, styles.tileContent) },
                        React.createElement("div", { className: classnames(styles.channelLogo, styles.metaLogo), style: logoStyle }),
                        React.createElement("div", { className: styles.metaContent },
                            React.createElement("h1", { className: styles.metaTitle }, !isLoading && title),
                            React.createElement("small", { className: styles.metaTime }, !isLoading && startTime && endTime && `${formatToHourString(startTime)} - ${formatToHourString(endTime)}`))))));
        } }));
};
export default Tile;
