var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import styles from './Animation.scss';
export var animationTypes;
(function (animationTypes) {
    animationTypes[animationTypes["fade"] = 0] = "fade";
    animationTypes[animationTypes["fadeIn"] = 1] = "fadeIn";
    animationTypes[animationTypes["slideIn"] = 2] = "slideIn";
})(animationTypes || (animationTypes = {}));
const stateClassNames = {
    // appear is sharing the same class as enter
    appear: styles.isEnter,
    appearActive: styles.isEnterActive,
    enter: styles.isEnter,
    enterActive: styles.isEnterActive,
    exit: styles.isExit,
    exitActive: styles.isExitActive,
};
const AnimationAnimate = (_a) => {
    var { type, children, in: inProp, active, appear, duration, className } = _a, rest = __rest(_a, ["type", "children", "in", "active", "appear", "duration", "className"]);
    const canAnimate = inProp == null
        ? appear || active
        : inProp;
    const style = { transitionDuration: `${duration}ms` };
    return (React.createElement(CSSTransition, Object.assign({}, rest, { appear: appear, className: classnames(styles[type], className), classNames: stateClassNames, in: appear || canAnimate, style: style, timeout: duration || 300, unmountOnExit: true }), children));
};
export default AnimationAnimate;
