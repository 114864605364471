import React from 'react';
import { connect } from 'react-redux';
import { flatMapDeep } from 'lodash';
import { Page, SwimmingLane, ProgramDetail, Spinner, Button, ChannelSelector, Icon } from '@source/view/components';
import styles from './Guide.scss';
import { getToken } from '@source/core/store/user';
import * as vinsonService from '@source/core/services/vinson';
import Helmet from 'react-helmet';
import { mediaQueryIsActive } from '@source/core/mediaQuery';
import { LocaleContext } from '@source/view/enhancers/Locale';
function getProgram(id, channels) {
    if (!id)
        return null;
    let foundProgram = null;
    channels.forEach((channel) => {
        channel.programs && channel.programs.forEach((program) => {
            if (program.id === id) {
                foundProgram = program;
            }
        });
    });
    return foundProgram;
}
function getChannel(id, channels) {
    if (!id)
        return null;
    return channels.find(c => c.id === id) || null;
}
const mapState = (state) => ({
    accessToken: getToken(state),
    mediaQuery: state.app.mediaQuery,
});
class GuidePage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            pending: false,
            channelSelectorOpen: false,
            channelData: [],
            selectedProgramId: null,
            currentOffset: 0,
        };
        this.fetchChannels = async () => {
            if (this.state.pending)
                return;
            if (!this.props.accessToken)
                return;
            try {
                this.setState({ pending: true });
                const offset = this.state.currentOffset;
                const channels = await vinsonService.fetchChannelList(this.props.accessToken, offset);
                const channelData = {
                    offset,
                    channels,
                };
                this.setState(prevState => ({
                    channelData: prevState.channelData.concat(channelData),
                }));
            }
            catch (err) {
            }
            finally {
                this.setState({ pending: false });
            }
        };
        this.loadMore = () => {
            // don't load more if there is one pending or the current already is 2 (wich should be the max)
            if (this.state.pending, this.state.currentOffset >= 2)
                return;
            this.setState(prevState => ({ currentOffset: prevState.currentOffset + 1 }), () => {
                this.fetchChannels();
            });
        };
        this.flattenChannels = (channelData) => flatMapDeep(channelData.map(c => c.channels));
        this.getProgram = getProgram;
        this.getChannel = getChannel;
        this.handleChannelClick = (programId) => {
            this.setState({ selectedProgramId: programId });
        };
        this.clearState = () => {
            this.setState({ selectedProgramId: null });
        };
    }
    async componentDidMount() {
        await this.fetchChannels();
    }
    render() {
        const { translate } = this.context;
        const { channelData, selectedProgramId } = this.state;
        const channels = this.flattenChannels(channelData);
        const selectedProgram = this.getProgram(selectedProgramId, channels);
        const selectedChannel = this.getChannel(selectedProgram ? selectedProgram.channelId : null, channels);
        const isSmall = mediaQueryIsActive(this.props.mediaQuery, 'small');
        return (React.createElement(Page, { title: translate('tv_guide'), pageType: "dashboard", className: styles.page, renderStickyBottom: (() => {
                if (this.state.channelSelectorOpen) {
                    return isSmall && (React.createElement("div", { className: styles.channelSelector },
                        React.createElement(Helmet, null,
                            React.createElement("body", { className: "is-fixed" })),
                        React.createElement("div", { className: styles.channelSelectorTitle },
                            translate('select_a_channel'),
                            React.createElement("button", { onClick: () => this.setState({ channelSelectorOpen: false }) },
                                React.createElement(Icon, { iconName: "close" }))),
                        React.createElement(ChannelSelector, { className: styles.channelSelectorWrapper }))) || null;
                }
                if (selectedProgram && selectedChannel) {
                    return (React.createElement("div", { className: styles.programDetail },
                        isSmall && (React.createElement(Helmet, null,
                            React.createElement("body", { className: "is-fixed" }))),
                        React.createElement("div", { className: styles.programDetailInner },
                            React.createElement(ProgramDetail, { channel: selectedChannel, program: selectedProgram, onCloseClick: this.clearState }))));
                }
                return null;
            })() },
            React.createElement(SwimmingLane, { className: styles.guide, onChannelClick: this.handleChannelClick, channelItems: channelData, selectedProgramId: this.state.selectedProgramId, handleChannelHeaderClick: isSmall ? () => this.setState({ channelSelectorOpen: true }) : undefined }),
            React.createElement("div", { style: { textAlign: 'center' } }, (() => {
                if (this.state.pending) {
                    return React.createElement(Spinner, null);
                }
                if (this.state.currentOffset < 2) {
                    return React.createElement(Button, { onClick: this.loadMore, text: translate('load_more') });
                }
                return null;
            })())));
    }
}
GuidePage.contextType = LocaleContext;
export default connect(mapState)(GuidePage);
