import * as React from 'react';
import useResizeObserver from './useResizeObserver';
export default function useOffsets(ref) {
    const [offsets, setOffsets] = React.useState(null);
    useResizeObserver(ref, ({ target }) => {
        if (target) {
            setOffsets({
                top: target.offsetTop,
                left: target.offsetLeft,
                width: target.offsetWidth,
                height: target.offsetHeight,
            });
        }
        else if (offsets !== null) {
            setOffsets(null);
        }
    });
    return offsets;
}
