import throttle from 'lodash/throttle';
import { getLocalStorage, saveLocalStorage, removeLocalStorage } from '../utils/localStorage';
const PERSIST_ACTION_TYPE = '@@INIT_PERSIST';
/**
 * persist the state through localStorage
 * @param name unique name of the reducer for localStorage
 * @param reducer
 * @param opts
 */
export default function persistReducer(name, reducer, opts = {}) {
    const storageKey = `@@state:${name}`;
    const persistedState = getLocalStorage(storageKey);
    let initialState = reducer(undefined, { type: PERSIST_ACTION_TYPE });
    let prevState = initialState;
    const shouldPerist = (state) => {
        if (opts.saveOnKey === undefined) {
            return true;
        }
        if (!!state[opts.saveOnKey]) {
            return true;
        }
        return false;
    };
    const clearPersistedState = () => {
        removeLocalStorage(storageKey);
    };
    if (persistedState && shouldPerist(persistedState)) {
        initialState = Object.assign({}, initialState, persistedState);
    }
    else if (persistedState) {
        clearPersistedState();
    }
    const persistState = throttle((state) => {
        if (shouldPerist(state)) {
            const filteredState = opts.whitelist
                ? {}
                : Object.assign({}, state);
            if (opts.whitelist) {
                opts.whitelist.forEach((key) => {
                    filteredState[key] = state[key];
                });
            }
            if (opts.blacklist) {
                opts.blacklist.forEach((key) => {
                    delete filteredState[key];
                });
            }
            saveLocalStorage(storageKey, filteredState);
        }
        else {
            clearPersistedState();
        }
    }, 2000, {
        leading: true,
        trailing: true,
    });
    return function persistedReducer(state = initialState, action) {
        const nextState = reducer(state, action);
        if (prevState !== nextState) {
            persistState(nextState);
            prevState = nextState;
        }
        return nextState;
    };
}
