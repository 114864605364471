import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { getChannelById, getCurrentPlayingProgram, fetchChannels, getCurrenttime, getChannelItems } from '@source/core/store/channel';
import { VideoPlayer, ChannelSelector, Timebar, Icon } from '@source/view/components';
import { getVolume, getToken, toggleMute } from '@source/core/store/user';
import styles from './Video.styles.scss';
import paths from '@source/core/constants/paths';
import { fetchProgramStream, fetchProgramDetails, heartbeat } from '@source/core/services/vinson';
import { formatToHourString } from '@source/core/utils/formatDate';
import postponePromise from '@source/core/utils/postponePromise';
import { LocaleContext } from '@source/view/enhancers/Locale';
/** Get the Channel by index, and return first/last depending on the index */
function getChannelByIndex(channels, index) {
    // if the next index is less then zero, return the first channel
    if (index < 0) {
        return channels[0];
    }
    // if the next index is more then the total length of the channels, return the last one
    if (index >= channels.length) {
        return channels[channels.length - 1];
    }
    // else, return the channel with the offset
    return channels[index];
}
/** Get the Channel and try to find the next one by offset */
function getChannelByIndexOffset(offset, channels, currentChannelId) {
    const currentChannelIndex = channels.findIndex(channel => channel.id === currentChannelId);
    const nextChannelIndex = currentChannelIndex + offset;
    return getChannelByIndex(channels, nextChannelIndex);
}
/** Get the previous Channel */
function getNextChannel(channels, currentChannelId) {
    return getChannelByIndexOffset(1, channels, currentChannelId);
}
/** Get the next Channel */
function getPrevChannel(channels, currentChannelId) {
    return getChannelByIndexOffset(-1, channels, currentChannelId);
}
class VideoPage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            pending: false,
            videoUrl: null,
            programDetails: null,
            detailsExpanded: false,
            videoInfo: null,
        };
        this.startHeartbeat = async () => {
            if (this.props.accessToken) {
                await heartbeat(this.props.accessToken);
                await new Promise((r) => {
                    this.intervalId = setTimeout(r, 1000 * 30);
                });
                this.startHeartbeat();
            }
        };
        this.getProgramDetails = async () => {
            const { currentProgram, accessToken } = this.props;
            this.setState({ programDetails: null });
            if (currentProgram && accessToken) {
                const programDetails = await fetchProgramDetails(accessToken, currentProgram.id);
                this.setState({ programDetails });
            }
        };
        this.getVideoUrl = () => {
            this.setState({ videoUrl: null, pending: true, videoInfo: null }, async () => {
                try {
                    const { channelId, selectedChannel } = this.props;
                    const channelName = selectedChannel ? selectedChannel.name : '<<empty>>';
                    const { accessToken } = this.props;
                    if (channelId && accessToken) {
                        const videoInfo = await postponePromise(800, fetchProgramStream(accessToken, channelId, channelName));
                        this.setState({ videoInfo });
                    }
                }
                catch (err) {
                    console.log(err);
                }
                finally {
                    this.setState({ pending: false });
                }
            });
        };
        this.handleBack = () => {
            this.props.history.push(paths.home);
        };
        this.handleNextChannel = () => {
            if (this.props.selectedChannel) {
                const nextChannel = getNextChannel(this.props.channels, this.props.selectedChannel.id);
                this.props.history.push(paths.video(nextChannel.id, nextChannel.slug));
            }
        };
        this.handlePrevChannel = () => {
            if (this.props.selectedChannel) {
                const nextChannel = getPrevChannel(this.props.channels, this.props.selectedChannel.id);
                this.props.history.push(paths.video(nextChannel.id, nextChannel.slug));
            }
        };
        this.handleMuteToggle = () => {
            this.props.toggleMute();
        };
        this.handleNumberedChannel = (number) => {
            if (this.props.selectedChannel) {
                const nextChannel = getChannelByIndex(this.props.channels, number - 1);
                this.props.history.push(paths.video(nextChannel.id, nextChannel.slug));
            }
        };
    }
    componentDidMount() {
        this.props.fetchChannels();
        this.getVideoUrl();
        this.getProgramDetails();
        this.startHeartbeat();
    }
    componentWillUnmount() {
        clearTimeout(this.intervalId);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.channelId !== this.props.channelId) {
            this.getVideoUrl();
        }
        const prevCurrentProgram = prevProps.currentProgram || { id: '<<FAKE_ID>>' };
        if (this.props.currentProgram !== undefined &&
            this.props.currentProgram.id !== prevCurrentProgram.id) {
            this.getProgramDetails();
        }
    }
    render() {
        const { translate } = this.context;
        const { programDetails, videoInfo } = this.state;
        const { selectedChannel, currentProgram, nextProgram, currentTime, volume, } = this.props;
        return (React.createElement(VideoPlayer.Container, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: cx(styles.video, styles.wrapperVideo) },
                    React.createElement("div", { className: styles.videoInner }, (() => {
                        let loadingObj = {
                            loadingTitle: '',
                            loadingMessage: '',
                            loadingSpinner: false,
                        };
                        if (this.state.pending) {
                            loadingObj = {
                                loadingTitle: currentProgram ? currentProgram.name : 'Video',
                                loadingMessage: translate('stream_starting', { name: ' ' }),
                                loadingSpinner: true,
                            };
                        }
                        if (videoInfo && !videoInfo.hasUrl) {
                            loadingObj = {
                                loadingTitle: translate('maximum_reached_title'),
                                loadingMessage: translate('maximum_reached_text'),
                                loadingSpinner: false,
                            };
                        }
                        return (React.createElement(VideoPlayer, Object.assign({ handleBack: this.handleBack, handlePrevChannel: this.handlePrevChannel, handleNextChannel: this.handleNextChannel, handleNumberedChannel: this.handleNumberedChannel, handleMute: this.handleMuteToggle, channel: selectedChannel || undefined, currentProgram: currentProgram, nextProgram: nextProgram, currentTime: currentTime, volume: volume, videoInfo: this.state.videoInfo }, loadingObj)));
                    })())),
                React.createElement("div", { className: cx(styles.wrapperDetails, styles.details) },
                    selectedChannel && nextProgram && currentProgram && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: cx(styles.detailsSection, styles.detailsNow) },
                            React.createElement("div", { className: styles.detailsNowRow, onClick: !this.state.detailsExpanded ? () => this.setState({ detailsExpanded: true }) : undefined },
                                this.state.detailsExpanded && (React.createElement("button", { className: styles.detailsNowCloseButton, onClick: () => this.setState({ detailsExpanded: false }) },
                                    React.createElement(Icon, { iconName: "close" }))),
                                React.createElement("img", { className: styles.detailsNowLogo, src: selectedChannel.logoImageUrl }),
                                React.createElement("div", { className: styles.detailsNowContent },
                                    React.createElement("div", { className: styles.detailsNowTitle }, currentProgram.name),
                                    React.createElement(Timebar, { startTime: currentProgram.startTime, endTime: currentProgram.endTime, currentTime: currentTime }))),
                            this.state.detailsExpanded && programDetails && (React.createElement("div", { className: styles.detailsNowDescription }, programDetails.description))),
                        React.createElement("div", { className: styles.detailsHeading },
                            "Straks op ",
                            selectedChannel.name),
                        React.createElement("div", { className: cx(styles.detailsSection, styles.detailsLater) },
                            React.createElement("div", { className: styles.detailsLaterTitle }, nextProgram.name),
                            React.createElement("div", { className: styles.detailsLaterTime },
                                formatToHourString(nextProgram.startTime),
                                " - ",
                                formatToHourString(nextProgram.endTime))))),
                    React.createElement("div", { className: styles.detailsHeading }, "Nu op TV"),
                    React.createElement(ChannelSelector, { className: styles.wrapperChannelSelector })))));
    }
}
VideoPage.contextType = LocaleContext;
export default connect((state, props) => {
    const foundChannel = state.channel.items.find(c => c.slug === props.match.params.channelName);
    const channelId = foundChannel ? foundChannel.id : '';
    const { currentProgram, nextProgram } = getCurrentPlayingProgram(state, channelId);
    return {
        channels: getChannelItems(state),
        accessToken: getToken(state),
        selectedChannel: getChannelById(state, channelId),
        currentProgram: currentProgram || undefined,
        nextProgram: nextProgram || undefined,
        currentTime: getCurrenttime(state),
        volume: getVolume(state),
        channelId,
    };
}, {
    fetchChannels,
    toggleMute,
}
// @ts-ignore Need to rewrite the Prop injection
)(VideoPage);
