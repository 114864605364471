/**
 * @description Formats a set of UNIX timestamps to something like: `12.00 - 12.30`
 * @param timestamp Unix timestamp
 */
export function formatToHourString(timestamp) {
    const date = new Date(timestamp * 1000);
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    return `${hh}:${mm}`;
}
/**
 * @description creates a Date object by converting a Unix timestamp
 * @param unixTimestamp Unix timestamp
 */
export function unixTimestampToDate(unixTimestamp) {
    // times 1000 to create a timestamp that Date can parse
    return new Date(unixTimestamp * 1000);
}
