var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import classnames from 'classnames';
import styles from './Grid.scss';
const GridRow = (_a) => {
    var { children, className, xSmall, small, medium, large, xLarge, as: ElementType } = _a, props = __rest(_a, ["children", "className", "xSmall", "small", "medium", "large", "xLarge", "as"]);
    const classes = classnames(styles.row, className);
    return (React.createElement(ElementType, Object.assign({}, props, { className: classes }), React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            const childProps = {
                xSmall: child.props.xSmall !== undefined ? child.props.xSmall : xSmall,
                small: child.props.small !== undefined ? child.props.small : small,
                medium: child.props.medium !== undefined ? child.props.medium : medium,
                large: child.props.large !== undefined ? child.props.large : large,
                xLarge: child.props.xLarge !== undefined ? child.props.xLarge : xLarge,
            };
            return React.cloneElement(child, childProps);
        }
        return child;
    })));
};
GridRow.defaultProps = {
    as: 'div',
};
export default GridRow;
