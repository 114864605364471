import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Page, Input, Container, Button, Form } from '@source/view/components';
import * as user from '@source/core/store/user';
import { logError } from '@source/core/utils/logger';
import { LocaleContext } from '@source/view/enhancers/Locale';
import paths from '@source/core/constants/paths';
const mapState = (state) => ({
    subscriptionEndTime: user.getSubscriptionEndDate(state),
    isPending: user.getPending(state),
    isExpired: !state.user.subscribed,
});
const mapDispatch = {
    extendUser: user.extendUser,
    logoutUser: user.logoutUser,
};
class AccountExtendPage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            key: '',
            errorMessage: null,
        };
        this.handleExtendAccount = async () => {
            const { translate } = this.context;
            if (this.props.isPending)
                return;
            try {
                this.setState({ errorMessage: null });
                await this.props.extendUser(this.state.key);
                this.props.history.push(paths.home);
            }
            catch (err) {
                this.setState({ errorMessage: translate('error_message_extend_code') });
                logError(err);
            }
        };
        this.goBack = async () => {
            await this.props.logoutUser();
            this.props.history.push(paths.login);
        };
        this.handleKeyChange = (value) => this.setState({ key: value });
    }
    render() {
        const { subscriptionEndTime, isExpired } = this.props;
        const { translate } = this.context;
        return (React.createElement(Page, { title: "Extend account" },
            React.createElement(Container, null,
                React.createElement(Form, { onSubmit: this.handleExtendAccount },
                    React.createElement(Form.Heading, null, translate('account_expiration_title')),
                    React.createElement(Form.Text, null, translate(isExpired
                        ? 'account_expired_text'
                        : 'account_expiration_text', {
                        endDate: format(subscriptionEndTime * 1000, 'DD-MM-YY, H:m'),
                    })),
                    this.state.errorMessage && (React.createElement(Form.ErrorMessage, null, this.state.errorMessage)),
                    React.createElement(Form.Row, null,
                        React.createElement(Input, { onChange: this.handleKeyChange, value: this.state.key, label: translate('code') })),
                    React.createElement(Form.Row, null,
                        isExpired && (React.createElement("button", { type: "button", onClick: this.goBack }, `< ${translate('back')}`)),
                        React.createElement(Button, { type: "button", fullWidth: true, className: Form.rowFillClassName, disabled: this.props.isPending, text: translate('extend'), onClick: this.handleExtendAccount }),
                        !isExpired && (React.createElement(Link, { to: paths.home }, `${translate('later')} >`)))))));
    }
}
AccountExtendPage.contextType = LocaleContext;
// @ts-ignore Need to rewrite the Prop injection
export default withRouter(connect(mapState, mapDispatch)(AccountExtendPage));
