import * as React from 'react';
export default class ImageLoader extends React.PureComponent {
    constructor() {
        super(...arguments);
        /** the mounted state of the component, used for if `this.setState` should be called */
        this.mounted = false;
        this.image = null;
        this.state = {
            loading: true,
            error: false,
        };
    }
    componentDidMount() {
        // component is mounted, ready to get state changes
        this.mounted = true;
        this.createImageLoader();
    }
    componentWillUnmount() {
        // component is dismounted, should not get state changes
        this.mounted = false;
    }
    componentDidUpdate(prevProps) {
        // create a new image loader when the src change
        // it will discard the previous image
        if (this.props.src !== prevProps.src) {
            this.createImageLoader();
        }
    }
    createImageLoader() {
        // If there is already an virtual image, clear the handlers and the image itself
        // before the image tries to handle the methods
        if (this.image) {
            this.image.onload = null;
            this.image.onerror = () => { };
            this.image.src = '';
            this.image = null;
        }
        this.setState({
            // set the image on loading if there is one
            loading: !!this.props.src,
            error: false,
        }, () => {
            if (this.props.src) {
                // create a virtual image so we can read when the image is loaded
                this.image = document.createElement('img');
                this.image.src = this.props.src;
                this.image.onload = () => {
                    if (this.mounted) {
                        this.setState({ loading: false, error: false });
                    }
                };
                this.image.onerror = () => {
                    if (this.mounted) {
                        this.setState({ loading: false, error: true });
                    }
                };
            }
        });
    }
    render() {
        // render the render prop as a function
        return this.props.render({
            loading: this.state.loading,
            error: this.state.error,
            src: this.props.src,
        });
    }
}
