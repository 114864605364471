import { createAction } from './utils';
export const getAppState = (state) => state.app;
export const getMediaQuery = (state) => getAppState(state).mediaQuery;
/*
    actions
------------------------------------------ */
var actionTypes;
(function (actionTypes) {
    actionTypes["SET_MEDIA_QUERY"] = "app/SET_MEDIA_QUERY";
})(actionTypes || (actionTypes = {}));
export const setMediaQuery = (payload) => createAction(actionTypes.SET_MEDIA_QUERY, payload);
const initialState = {
    mediaQuery: 'medium',
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MEDIA_QUERY:
            return Object.assign({}, state, { mediaQuery: action.payload });
        default: return state;
    }
};
