import React from 'react';
import { Link, NavLink, Route } from 'react-router-dom';
import paths from '@source/core/constants/paths';
import Logo from '../Logo';
import Icon from '../Icon';
import styles from './Menu.scss';
import { LocaleContext } from '@source/view/enhancers/Locale';
class Menu extends React.Component {
    constructor() {
        super(...arguments);
        this.menuItems = [
            {
                label: this.context.translate('watch_tv'),
                path: paths.home,
                iconName: 'tvWatch',
            },
            {
                label: this.context.translate('tv_guide'),
                path: paths.guide,
                iconName: 'tvGuide',
            },
            {
                label: this.context.translate('settings'),
                path: paths.settings,
                iconName: 'settings',
            },
        ];
    }
    render() {
        const items = this.menuItems.map(element => (React.createElement(Route, { key: element.path, exact: true, path: element.path, children: ({ match }) => (React.createElement(NavLink, { to: element.path, className: styles.item, activeClassName: styles.isActive, exact: true },
                React.createElement(Icon, { iconName: element.iconName, className: styles.itemIcon, active: !!match }),
                React.createElement("span", { className: styles.itemText }, element.label))) })));
        return (React.createElement("div", { className: styles.container },
            React.createElement(Link, { to: paths.home, className: styles.logo },
                React.createElement(Logo, null)),
            items));
    }
}
Menu.contextType = LocaleContext;
export default Menu;
