import * as React from 'react';
import useResizeObserver from './useResizeObserver';
export default function useTextLines(ref) {
    const [lineHeight, setLineHeight] = React.useState(0);
    useResizeObserver(ref, (entry) => {
        if (entry.target) {
            const computedStyle = window.getComputedStyle(entry.target);
            setLineHeight(parseFloat(computedStyle.lineHeight || '0'));
        }
        else if (lineHeight !== 0) {
            setLineHeight(0);
        }
    });
    return lineHeight;
}
