function isCurrentProgram(currentTime, program) {
    return !!program && (currentTime >= program.startTime &&
        currentTime < program.endTime);
}
const DEFAULT_VALUE = null;
function getCurrentProgramFunc(channels, channelId, currentTime) {
    const channel = channels.find(channel => channel.id === channelId);
    let currentProgram = DEFAULT_VALUE;
    let nextProgram = DEFAULT_VALUE;
    if (channel && channel.programs) {
        const foundIndex = channel.programs.findIndex(program => isCurrentProgram(currentTime, program)) || DEFAULT_VALUE;
        if (foundIndex) {
            currentProgram = channel.programs[foundIndex];
            nextProgram = channel.programs[foundIndex + 1];
        }
    }
    return {
        currentProgram,
        nextProgram,
    };
}
const createMemoizedGetCurrentProgram = () => {
    const cache = new Map();
    let lastChannels = null;
    function checkCache(currentCache, currentTime) {
        // if there is a program, check if it is the current one
        if (currentCache.result.currentProgram) {
            return isCurrentProgram(currentTime, currentCache.result.currentProgram);
        }
        // if there is none, see if the currentTime has changed
        if (currentTime !== currentCache.lastCurrentTime) {
            return false;
        }
        // else, nothing in the cache has changed
        return true;
    }
    return (channels, channelId, currentTime) => {
        const currentCache = cache.get(channelId);
        if (lastChannels !== channels ||
            currentCache === undefined ||
            !checkCache(currentCache, currentTime)) {
            const result = getCurrentProgramFunc(channels, channelId, currentTime);
            cache.set(channelId, {
                result,
                lastCurrentTime: currentTime,
            });
            lastChannels = channels;
            return result;
        }
        return currentCache
            ? currentCache.result
            : {
                currentProgram: DEFAULT_VALUE,
                nextProgram: DEFAULT_VALUE,
            };
    };
};
export default createMemoizedGetCurrentProgram();
