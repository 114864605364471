var StreamType;
(function (StreamType) {
    StreamType["mpd"] = "mpd";
    StreamType["m3u8"] = "m3u8";
})(StreamType || (StreamType = {}));
function getOSScheme() {
    // @ts-ignore
    if (Meister.Browser.isMacintosh)
        return 'Mac';
    // @ts-ignore
    if (Meister.Browser.isAndroid)
        return 'Android';
    // @ts-ignore
    if (Meister.Browser.isiOS)
        return 'iOS';
    return 'None';
}
export class VideoInfo {
    constructor(params) {
        this.ChromecastSender = {
            mediaInfo: {
                streamType: 'LIVE',
            },
        };
        this.googleAnalyticsOptions = {
            eventCategory: 'play',
            heartBeatInterval: 0,
            label: params.channelName,
        };
        this.hasUrl = !params.deviceLimit;
        const customHeaders = {
            'X-Triple-DeviceInfo': getOSScheme(),
            'X-Triple-DeviceRightsId': 'WV001',
            'X-Triple-TokenId': params.userToken,
            'X-Triple-DeviceId': 'dsadssad',
            'X-Triple-AssetId': params.assetId,
            'X-Triple-ReferenceId': 'adsdsaasdsadsad',
        };
        // @ts-ignore
        if (Meister.Browser.isEdge || Meister.Browser.isIE) {
            // PlayReady configuration
            this.src = params.dashUrl || null;
            this.type = StreamType.mpd;
            this.drmConfig = {
                playready: params.playreadyLicenseUrl ? {
                    drmServerUrl: params.playreadyLicenseUrl,
                    customHeaders: Object.assign({}, customHeaders, { 'X-Triple-DeviceRightsId': 'PR001' }),
                } : undefined,
            };
            // @ts-ignore
        }
        else if (Meister.Browser.isSafari) {
            // Fairplay configuration
            this.src = params.hlsUrl || null;
            this.type = StreamType.m3u8;
            this.drmConfig = {
                fairplay: (params.fairplayLicenseUrl && params.fairplayCertificateUrl) ? {
                    drmServerUrl: params.fairplayLicenseUrl,
                    drmCertificateUrl: params.fairplayCertificateUrl,
                    customHeaders: Object.assign({}, customHeaders, { 'X-Triple-DeviceRightsId': 'FP001' }),
                } : undefined,
            };
        }
        else {
            // Widevine configuration
            this.src = params.dashUrl || null;
            this.type = StreamType.mpd;
            this.drmConfig = {
                widevine: params.widevineLicenseUrl ? {
                    drmServerUrl: params.widevineLicenseUrl,
                    customHeaders: Object.assign({}, customHeaders, { 'X-Triple-DeviceRightsId': 'WV001' }),
                    scheme: {
                        kid: '%%KID%%',
                        drm_info: '%%KEY_MESSAGE%%',
                    },
                } : undefined,
            };
        }
    }
}
