import * as React from 'react';
import { Page, Container, SettingsBlock } from '../components';
import Markdown from '@source/view/components/Markdown';
import { LocaleContext } from '@source/view/enhancers/Locale';
class PrivacyPage extends React.Component {
    render() {
        const { translate } = this.context;
        return (React.createElement(Page, { title: "Privacy", pageType: "dashboard" },
            React.createElement(Container, null,
                React.createElement(SettingsBlock, null,
                    React.createElement(Markdown, { source: translate('privacy_text') })))));
    }
}
PrivacyPage.contextType = LocaleContext;
export default PrivacyPage;
