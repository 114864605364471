import uuid from 'uuid/v4';
import { createAction } from './utils';
export class Notification {
    constructor(message, duration, type) {
        this.id = uuid();
        this.message = message;
        this.duration = duration || 3000;
        this.type = type || 'message';
    }
}
/*
    Selectors
------------------------------------------ */
export const getNotifications = (state) => state.notifications;
/*
    Actions & ActionTypes
------------------------------------------ */
var actionTypes;
(function (actionTypes) {
    actionTypes["ADD_NOTIFICATION"] = "notifications/ADD_NOTIFICATION";
    actionTypes["REMOVE_NOTIFICATION"] = "notifications/REMOVE_NOTIFICATION";
})(actionTypes || (actionTypes = {}));
export const addNotification = (message, type, duration) => createAction(actionTypes.ADD_NOTIFICATION, { message, type, duration });
export const removeNotification = (id) => createAction(actionTypes.REMOVE_NOTIFICATION, { id });
/*
    Reducer
------------------------------------------ */
const initialValue = [];
export function reducer(state = initialValue, action) {
    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION:
            return state.concat(new Notification(action.payload.message, action.payload.duration, action.payload.type));
        case actionTypes.REMOVE_NOTIFICATION:
            return state.filter(notification => notification.id !== action.payload.id);
        default:
            return state;
    }
}
