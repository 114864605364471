import * as React from 'react';
import cx from 'classnames';
import styles from './Dropdown.scss';
function getThemeClassName(theme) {
    switch (theme) {
        case 'shaded': return styles.isShaded;
        case 'inset': return styles.isInsetted;
        case 'inset-first': return cx(styles.isInsetted, styles.isFirst);
        case 'inset-last': return cx(styles.isInsetted, styles.isLast);
        default: return null;
    }
}
export default function DropdownOption(props) {
    const classes = cx(styles.option, getThemeClassName(props.theme), {
        [styles.hasNoPadding]: props.noPadding,
    });
    const ElementType = props.onClick ? 'button' : 'div';
    return (
    // @ts-ignore
    React.createElement(ElementType, { className: classes, onClick: props.onClick }, props.children));
}
