import { combineReducers } from 'redux';
import * as app from './app';
import * as user from './user';
import * as channel from './channel';
import * as notifications from './notifications';
export const reducers = combineReducers({
    app: app.reducer,
    user: user.reducer,
    channel: channel.reducer,
    notifications: notifications.reducer,
});
