import * as React from 'react';
import styles from './ToastMessage.scss';
import { LocaleContext } from '@source/view/enhancers/Locale';
export default class ToastMessageItem extends React.Component {
    constructor() {
        super(...arguments);
        this.startTimer = () => {
            this.stopTimer();
            this.timeoutId = window.setTimeout(() => {
                this.handleRemove();
            }, this.props.duration);
        };
        this.stopTimer = () => {
            clearTimeout(this.timeoutId);
        };
    }
    componentDidMount() {
        this.startTimer();
    }
    handleRemove() {
        this.props.onRemove(this.props.id);
    }
    render() {
        const { translate } = this.context;
        const { message } = this.props;
        return (React.createElement("div", { className: styles.item, onPointerEnter: this.stopTimer, onPointerLeave: this.startTimer }, translate(message)));
    }
}
ToastMessageItem.contextType = LocaleContext;
ToastMessageItem.defaultProps = {
    duration: 3000,
};
