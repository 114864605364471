import { Channel } from '@source/core/models/Channel';
import ProgramDetails from '@source/core/models/ProgramDetails';
import { VideoInfo } from '../../models/VideoInfo';
import cachePromise from '../cachePromise';
import fetchUrl from './fetchUrl';
import { meisterAvailable } from '@source/view/components/VideoPlayer/MeisterPlayer';
export async function fetchChannelList(userToken, offset = 0) {
    const [channelListData, programListData] = await Promise.all([
        cachePromise('channel-list', () => fetchUrl({ endpoint: '/channel/list', userToken })),
        fetchUrl({ endpoint: `/program/list?offset=${offset}`, userToken }),
    ]);
    const result = channelListData.channels.map((channelListData) => {
        const programList = programListData.channels.find(p => p.id === channelListData.id);
        return programList
            ? new Channel(channelListData, programList.programs)
            : new Channel(channelListData);
    });
    return result;
}
export async function fetchProgramDetails(userToken, programId) {
    const endpoint = `/program/detail?id=${programId}`;
    const result = await fetchUrl({ endpoint, userToken });
    return new ProgramDetails({
        id: result.id.toString(),
        name: result.name,
        description: result.description,
        imageUrlPortrait: result.imageUrlPortrait,
        imageUrlLandscape: result.imageUrlLandscape,
    });
}
export async function fetchProgramStream(userToken, programId, programName) {
    const endpoint = `/channel/play?id=${programId}`;
    const result = await fetchUrl({ endpoint, userToken });
    await meisterAvailable;
    const videoInfo = new VideoInfo(Object.assign({}, result, { userToken, channelName: programName }));
    return videoInfo;
}
export async function heartbeat(userToken) {
    const endpoint = '/playback/heartbeat';
    const result = await fetchUrl({ endpoint, userToken });
    return result;
}
