var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import classnames from 'classnames';
import styles from './Container.scss';
const Container = (_a) => {
    var { children, className, fullWidth, theme, as: ElementType } = _a, rest = __rest(_a, ["children", "className", "fullWidth", "theme", "as"]);
    const containerClasses = classnames(styles.container, className, {
        [styles.isFull]: fullWidth,
        [styles.isSlim]: theme === 'slim',
    });
    return (React.createElement(ElementType, Object.assign({ className: containerClasses }, rest), children));
};
Container.defaultProps = {
    as: 'div',
};
export default Container;
