import * as React from 'react';
import cx from 'classnames';
import styles from './Form.scss';
const FormHeading = ({ align, children }) => {
    const className = cx(styles.heading, {
        [styles.isCentered]: align === 'center',
    });
    return (React.createElement("h1", { className: className }, children));
};
export default FormHeading;
