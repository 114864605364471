import { GOOGLE_ANALYTICS_TRACKING_ID } from '@source/core/constants/api';
export const meisterAvailable = new Promise((resolve) => {
    const pollMeister = () => {
        if (window.Meister) {
            resolve();
        }
        else {
            setTimeout(pollMeister, 100);
        }
    };
    pollMeister();
});
export default async function createMeisterPlayer(el) {
    return meisterAvailable.then(() => {
        const meisterPlayerInstance = new window.Meister(el, {
            Html5Player: {
                iosPlaysInline: true,
            },
            global: {
                autoplay: true,
                customFullscreenElement: document.getElementById('MeisterFullScreen'),
            },
            chromecastSender: {
                appId: '38854D15',
            },
            GoogleAnalytics: {
                trackingID: GOOGLE_ANALYTICS_TRACKING_ID,
            },
        });
        return meisterPlayerInstance;
    });
}
