import * as React from 'react';
import hoistStatics from 'hoist-non-react-statics';
/**
 * Defers the render for faster perceived rendering
 *
 * @export
 * @param WrappedComponent
 */
export default function deferComponentRender(WrappedComponent) {
    class DeferredRenderWrapper extends React.Component {
        constructor() {
            super(...arguments);
            this.mounted = false;
            this.state = { shouldRender: false };
        }
        componentDidMount() {
            this.mounted = true;
            // wrapped in a double requestAnimationFrame so
            // the component is defered as long as possible
            // for it to render without blocking the other
            // components to render themselves
            window.requestAnimationFrame(() => {
                window.requestAnimationFrame(() => {
                    this.mounted && this.setState({ shouldRender: true });
                });
            });
        }
        componentWillUnmount() {
            this.mounted = false;
        }
        render() {
            return this.state.shouldRender
                ? React.createElement(WrappedComponent, Object.assign({}, this.props))
                : null;
        }
    }
    return hoistStatics(DeferredRenderWrapper, WrappedComponent);
}
