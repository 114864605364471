/**
 * Postpones any promise to a minimal waiting time
 * @param postpone minimal number a promise should be postponed
 * @param promise
 */
async function postponePromise(postpone, promise) {
    const [value] = await Promise.all([
        promise,
        new Promise(r => setTimeout(r, postpone)),
    ]);
    return value;
}
export default postponePromise;
