import * as React from 'react';
import cx from 'classnames';
import styles from './Steps.scss';
function StepItem(props) {
    const isActiveClass = props.active ? styles.isActive : '';
    return (React.createElement(React.Fragment, null,
        props.step !== 1 && (React.createElement("div", { className: cx(styles.itemLine, isActiveClass) })),
        React.createElement("div", { className: cx(styles.itemDot, isActiveClass) },
            React.createElement("div", { className: styles.itemDotLabel },
                props.label,
                props.active && (React.createElement("div", null,
                    props.step,
                    "/",
                    props.totalSteps))))));
}
export default class Steps extends React.Component {
    render() {
        const children = React.Children.toArray(this.props.children);
        return (React.createElement("div", { className: styles.container }, children.map((child, index) => {
            return React.cloneElement(child, {
                step: index + 1,
                totalSteps: children.length,
            });
        })));
    }
}
Steps.Item = StepItem;
