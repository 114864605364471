import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { parseUrl } from 'query-string';
import paths from '@source/core/constants/paths';
import * as user from '@source/core/store/user';
import { Page, Container } from '@source/view/components';
import { LocaleContext } from '@source/view/enhancers/Locale';
const mapDispatch = {
    activateUser: user.activatedUser,
};
class EmailActivationPage extends React.Component {
    constructor(props) {
        super(props);
        const { query } = parseUrl(props.location.search);
        this.state = {
            viewState: 'pending',
            key: query.key || prompt('paste in the activation code') || '',
        };
    }
    async componentDidMount() {
        try {
            if (this.state.key) {
                await this.props.activateUser(this.state.key);
                this.setState({ viewState: 'succes' });
            }
        }
        catch (err) {
            this.setState({ viewState: 'error' });
        }
    }
    render() {
        const { viewState } = this.state;
        const { translate } = this.context;
        return (React.createElement(Page, { title: "Email activeren" },
            React.createElement(Container, null, (() => {
                if (viewState === 'pending') {
                    return (React.createElement("h1", null, translate('activation_validation')));
                }
                if (viewState === 'succes') {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("h1", null, translate('activation_success')),
                        React.createElement(Link, { to: paths.login }, translate('activation_login'))));
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement("h1", null, translate('activation_error')),
                    React.createElement(Link, { to: paths.register }, translate('activation_register'))));
            })())));
    }
}
EmailActivationPage.contextType = LocaleContext;
export default withRouter(connect(null, mapDispatch)(EmailActivationPage));
