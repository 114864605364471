import * as React from 'react';
import { connect } from 'react-redux';
import * as user from '@source/core/store/user';
import Icon from './Icon';
import Dropdown from './Dropdown';
import RangeSlider from './RangeSlider';
function mapState(state) {
    return {
        volume: user.getVolume(state),
    };
}
const mapDispatch = {
    changeVolume: user.changeVolume,
    toggleMute: user.toggleMute,
};
function getVolumeIconName(volume) {
    if (volume === 0) {
        return 'volumeOff';
    }
    if (volume < 30) {
        return 'volumeLow';
    }
    if (volume < 70) {
        return 'volumeMedium';
    }
    return 'volumeHigh';
}
class VolumeControl extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isDragging: false,
        };
        this.handleDragStart = () => this.setState({ isDragging: true });
        this.handleDragEnd = () => this.setState({ isDragging: false });
    }
    render() {
        const { volume, className, changeVolume } = this.props;
        return (React.createElement(Dropdown, { expanded: this.state.isDragging, className: className, inner: React.createElement(Icon, { onClick: this.props.toggleMute, iconName: getVolumeIconName(volume) }) },
            React.createElement(Dropdown.Option, null,
                React.createElement(RangeSlider, { maxValue: 100, value: volume, onChange: changeVolume, onDragStart: this.handleDragStart, onDragEnd: this.handleDragEnd }))));
    }
}
// @ts-ignore Need to rewrite the Prop injection
export default connect(mapState, mapDispatch)(VolumeControl);
