export function saveLocalStorage(name, value, options = {}) {
    let valueToBeSaved = value;
    if (options.whitelist) {
        valueToBeSaved = options.whitelist.reduce((acc, key) => {
            return Object.assign({}, acc, { [key]: value[key] });
        }, {});
    }
    localStorage.setItem(name, JSON.stringify(valueToBeSaved));
}
export function getLocalStorage(name) {
    const value = localStorage.getItem(name);
    return value && JSON.parse(value);
}
export function removeLocalStorage(name) {
    localStorage.removeItem(name);
}
