import * as React from 'react';
import cx from 'classnames';
import { Icon, Timebar } from '@source/view/components';
import { formatToHourString } from '@source/core/utils/formatDate';
import ChannelSelect from './VideoPlayerUi.ChannelSelect';
import styles from './VideoPlayerUi.Info.scss';
class VideoPlayerInfo extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showDescription: false,
        };
        this.toggleDescription = () => this.setState(s => ({ showDescription: !s.showDescription }));
        this.hideDescription = () => this.setState({ showDescription: false });
    }
    componentDidUpdate(prevProps) {
        if (this.props.hideControls !== prevProps.hideControls) {
            if (this.props.hideControls) {
                this.timeoutId = window.setTimeout(() => {
                    this.setState({ showDescription: false });
                }, 500);
            }
            else {
                window.clearTimeout(this.timeoutId);
            }
        }
    }
    render() {
        const { startTime, endTime, currentTime, currentChannel, nextTitle, nextStartTime, nextEndTime, } = this.props;
        const hasDescription = !!(this.props.description && this.state.showDescription);
        const containerClasses = cx(styles.container, {
            [styles.hasDescription]: hasDescription,
        });
        return (React.createElement("div", { className: containerClasses },
            React.createElement("div", { className: cx(styles.containerMain, styles.main) },
                currentChannel && currentChannel.logoImageUrl && (React.createElement("img", { src: currentChannel.logoImageUrl, className: styles.mainChannel })),
                React.createElement(ChannelSelect, { channelLogo: currentChannel ? currentChannel.logoImageUrl : undefined, currentChannelId: currentChannel ? currentChannel.id : undefined, className: styles.mainChannelSelector }),
                React.createElement("div", { className: cx(styles.info, styles.mainInfo) },
                    this.props.description
                        ? (React.createElement("button", { className: styles.infoTitle, onClick: this.toggleDescription }, this.props.title))
                        : (React.createElement("h1", { className: styles.infoTitle }, this.props.title)),
                    React.createElement(Timebar, { className: styles.infoTimebar, startTime: startTime, endTime: endTime, currentTime: currentTime }),
                    (hasDescription) && (React.createElement("p", { className: styles.infoDescription }, this.props.description)),
                    React.createElement("button", { onClick: this.hideDescription, className: styles.infoCloseButton },
                        React.createElement(Icon, { iconName: "close" })))),
            nextTitle && (React.createElement("div", { className: cx(styles.containerAside, styles.aside) },
                React.createElement("p", { className: styles.asideText }, "Straks"),
                React.createElement("h1", { className: styles.asideTitle }, nextTitle),
                nextStartTime && nextEndTime && (React.createElement("p", { className: styles.asideText },
                    formatToHourString(nextStartTime),
                    " - ",
                    formatToHourString(nextEndTime)))))));
    }
}
export default VideoPlayerInfo;
