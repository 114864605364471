import * as React from 'react';
import cx from 'classnames';
import styles from './Form.scss';
const FormText = (props) => {
    const textStyles = cx(styles.text, {
        [styles.isSmall]: props.small,
    });
    return (React.createElement("div", { className: textStyles }, props.children));
};
export default FormText;
