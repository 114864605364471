var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import classnames from 'classnames';
import styles from './Grid.scss';
function createGridClasses(breakpoints) {
    return Object.keys(breakpoints).reduce((acc, key) => {
        const value = breakpoints[key];
        if (value) {
            const bpName = key.replace(/^[a-z]/, text => text.toUpperCase());
            return `${acc} ${styles[`column${bpName}${value}`]}`;
        }
        return acc;
    }, '');
}
const GridColumn = (_a) => {
    var { as: ElementType, className, xSmall, small, medium, large, xLarge, children } = _a, props = __rest(_a, ["as", "className", "xSmall", "small", "medium", "large", "xLarge", "children"]);
    const itemClasses = classnames(className, styles.column, createGridClasses({
        xSmall,
        small,
        medium,
        large,
        xLarge,
    }));
    return (React.createElement(ElementType, Object.assign({ className: itemClasses }, props), children));
};
GridColumn.defaultProps = {
    as: 'div',
};
export default GridColumn;
