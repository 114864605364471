import * as React from 'react';
import cx from 'classnames';
import { formatToHourString } from '@source/core/utils/formatDate';
import styles from './Timebar.scss';
export default function TimeBar({ noText = false, startTime = 0, endTime = 0, currentTime = 0, className, compact, }) {
    const durationWidth = (currentTime - startTime) / (endTime - startTime) * 100;
    const durationStyles = {
        width: durationWidth > 100
            ? `100%`
            : `${durationWidth}%`,
    };
    const classes = cx(styles.timebar, className, {
        [styles.isCompact]: compact,
    });
    return (React.createElement("div", { className: classes },
        !noText && (React.createElement("div", { className: styles.timebarText },
            formatToHourString(startTime),
            " - ",
            formatToHourString(endTime))),
        React.createElement("div", { className: styles.timebarBar },
            React.createElement("div", { className: styles.timebarDuration, style: durationStyles }))));
}
