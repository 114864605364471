import React from 'react';
import classnames from 'classnames';
import styles from './Message.scss';
const Message = ({ message, className, rounded, inline, error, }) => {
    const containerClasses = classnames(styles.container, className, {
        [styles.isRounded]: rounded,
        [styles.isInline]: inline,
        [styles.isError]: error,
    });
    return (React.createElement("div", { className: containerClasses }, message));
};
Message.defaultProps = {
    rounded: false,
    inline: false,
    error: false,
};
export default Message;
