import * as React from 'react';
import ReactMarkdown from 'react-markdown';
const Markdown = ({ source }) => {
    console;
    if (source) {
        return (React.createElement(ReactMarkdown, { source: source }));
    }
    return null;
};
export default Markdown;
