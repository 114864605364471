import '@babel/polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Root from './view/root';
import { configureStore } from './core/store';
import { setCurrenttime } from '@source/core/store/channel';
import { setMediaQuery } from '@source/core/store/app';
import { mediaQuery } from '@source/core/mediaQuery';
import history from '@source/core/history';
import './styles/global.scss';
const { store } = configureStore();
const mountElement = document.getElementById('mount');
const CURRENT_TIME_INTERVAL = 2000;
setInterval(() => store.dispatch(setCurrenttime(Date.now() / 1000)), CURRENT_TIME_INTERVAL);
mediaQuery.on('change', mq => store.dispatch(setMediaQuery(mq)));
function renderReact() {
    ReactDOM.render(React.createElement(Root, { store: store, history: history }), mountElement);
}
async function bootstrapApp() {
    const r = await Promise.all([
        new Promise(r => mediaQuery.once('change', r)),
    ]);
    renderReact();
}
bootstrapApp()
    .catch(console.error);
