import fetchUrl from './fetchUrl';
import { subscriptionActivate } from './subscription';
import UserInfo from '../../models/UserInfo';
export const userSignIn = async (email, password) => {
    const response = await fetchUrl({
        endpoint: '/user/signin',
        method: 'POST',
        body: {
            password,
            email,
        },
    });
    return new UserInfo(response);
};
export const userRegister = async (email, password, registerCode) => {
    const response = await fetchUrl({
        endpoint: '/user/register',
        method: 'POST',
        body: {
            email,
            password,
        },
    });
    await subscriptionActivate(registerCode, response.token);
    return new UserInfo(response);
};
export const userSignOut = async () => {
    // for future cleanup on the server
};
export const forgotPassword = () => fetchUrl({ endpoint: '/user/forgot' });
export const userStatus = async (userToken) => {
    if (!userToken)
        throw new Error('no token');
    const response = await fetchUrl({
        endpoint: '/user/status',
        userToken,
    });
    return new UserInfo(response);
};
export const userActivate = (code) => fetchUrl({
    endpoint: '/user/activate',
    method: 'POST',
    body: {
        code,
    },
}).then(r => new UserInfo(r));
export const userRequestPasswordReset = (email) => fetchUrl({
    endpoint: '/user/forgot',
    method: 'POST',
    body: { email },
});
export const userPasswordReset = (code, password) => fetchUrl({
    endpoint: '/user/newpassword',
    method: 'POST',
    body: {
        code,
        password,
    },
});
export const userChangePassword = (userToken, oldPassword, newPassword) => fetchUrl({
    endpoint: '/user/update',
    method: 'POST',
    userToken,
    body: {
        password: oldPassword,
        newPassword,
    },
});
