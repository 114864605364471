var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import cx from 'classnames';
import FormText from './Form.Text';
import FormHeading from './Form.Heading';
import FormRow from './Form.Row';
import FormErrorMessage from './Form.ErrorMessage';
import FormContainer from './Form.Container';
import styles from './Form.scss';
class Form extends React.Component {
    constructor() {
        super(...arguments);
        this.inputRef = React.createRef();
        this.handleSubmit = (ev) => {
            ev.preventDefault();
            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }
            if (this.props.onSubmit) {
                this.props.onSubmit();
            }
        };
    }
    render() {
        const _a = this.props, { children, onSubmit, className, fullWidth } = _a, props = __rest(_a, ["children", "onSubmit", "className", "fullWidth"]);
        const containerClasses = cx(styles.container, className, {
            [styles.isFullWidth]: fullWidth,
        });
        return (React.createElement("form", Object.assign({}, props, { className: containerClasses, onSubmit: this.handleSubmit }),
            React.createElement("input", { ref: this.inputRef, className: styles.containerSubmitInput, type: "submit", value: "submit" }),
            children));
    }
}
Form.ErrorMessage = FormErrorMessage;
Form.Container = FormContainer;
Form.Text = FormText;
Form.Heading = FormHeading;
Form.Row = FormRow;
Form.rowFillClassName = styles.rowFill;
export default Form;
