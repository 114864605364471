import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { LocaleProvider } from './enhancers/Locale';
import App from './app';
const Root = ({ store, history }) => (React.createElement(ReduxProvider, { store: store },
    React.createElement(Router, { history: history },
        React.createElement(LocaleProvider, null,
            React.createElement(App, null)))));
export default hot(module)(Root);
