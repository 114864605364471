var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import cx from 'classnames';
import styles from './TextTruncate.scss';
const TextTruncate = (_a) => {
    var { as: ElementType, className, text } = _a, props = __rest(_a, ["as", "className", "text"]);
    return (React.createElement(ElementType, Object.assign({ className: cx(styles.text, className) }, props), text));
};
TextTruncate.defaultProps = {
    lines: 1,
    as: 'button',
};
export default TextTruncate;
